import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Divider, message, Card, Table, Checkbox, Form, Alert, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import $ from 'jquery';
import Cookies from 'universal-cookie';
const { Meta } = Card;
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const CaseSheetTabPrescription = ({ parameterP }) => {
  const buttonStyle = { background: 'white', border: 'none' };

  const [diseaseInput, setDiseaseInput] = useState('');


  const [combinationName, setCombinationName] = useState('');
  const [combinationNotes, setCombinationNotes] = useState('');
  const [combinationInstructions, setCombinationInstructions] = useState<any>('');
  const [combinationCounter, setCombinationCounter] = useState<any>('');
  const [medicineName, setMedicineName] = useState('');
  const [combinationDosage, setCombinationDosage] = useState('');
  const [combinationUid, setCombinationUid] = useState('');

  const [combinationMedicineType, setCombinationMedicineType] = useState('');

  const [combinationMorning, setCombinationMorning] = useState(false);
  const [combinationAfternoon, setCombinationAfternoon] = useState(false);
  const [combinationEvening, setCombinationEvening] = useState(false);
  const [combinationBedtime, setCombinationBedtime] = useState(false);

  const [sinceWhenInput, setSinceWhenInput] = useState('');
  const [selectedCaseDescription, setSelectedCaseDescription] = useState('');
  const [selectedCourceType, setSelectedCourceType] = useState('');
  const [selectedCourceDuration, setSelectedCourceDuration] = useState('');
  const [selectedInternalNotes, setSelectedInternalNotes] = useState('');

  const [combinationList, setCombinationList] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [diagnosisInput, setDiagnosisInput] = useState('');
  const [treatingInput, setTreatingInput] = useState(false);

  const [extraMedicineName, setExtraMedicineName] = useState('');
  const [extraMedicineDosage, setExtraMedicineDosage] = useState('');
  const [extraMedicineList, setExtraMedicineList] = useState([]);

  const [boosterMedicineName, setBoosterMedicineName] = useState('');
  const [boosterMedicineDosage, setBoosterMedicineDosage] = useState('');
  const [boosterMedicineList, setBoosterMedicineList] = useState([]);
  const [medicineInfo, setMedicineInfo] = useState<any>([]);
  //const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [courceType, setCourceType] = useState('');
  const [prescriptionCreateStatus, setPrescriptionCreateStatus] = useState('0');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {

    const fetchPrescriptionData = async (appointmentId) => {
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        }
      };
      fetch(process.env.React_App_AWS_API+'api/appointments/' + parameterP + '/prescription',rheader)
        .then(response => response.json())
        .then(data => {
          // Process the received data here
          console.log("################################9999999999")
          console.log(data);
          if (data.status == false) {

          } else {
            //setExtraMedicineList(data.data.medicines.extraMedicines);
            //setBoosterMedicineList(data.data.medicines.boosters);
            //setCombinationList(data.data.medicines.combinations);
            setPrescriptionCreateStatus('1');
            setCombinationList(data.data.medicines);
            setCombinationCounter(combinationList.length);
            setSelectedCourceDuration(data.data.courseDuration);
            setSelectedCourceType(data.data.courseType);

          }


          console.log(data);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error(error);
        });

    };

    fetchPrescriptionData(parameterP);
  }, []);




  const expandedRowRender = (record) => (
    <div key={record.key}>
      {/* <p>Notes: {record.notes}</p>
      <p>Instructions: {record.instructions}</p> */}
      <table>
        <thead>
          <tr>
            {/* <th>Med UID</th> */}
            <th>Pharmacy Notes</th>
            <th>Usage Instructions</th>
          </tr>
        </thead>
        <tbody>
          <tr >
            {/* <td>{record.uid}</td> */}
            <td>
              <TextArea
                value={record.notes}
                onChange={(e) => handleChangeCombinationExt(record.key, 'notes', e.target.value)}
                defaultValue='Pharmacy Notes' rows={3} />
            </td>
            <td>{record.instructions}</td>
          </tr>

        </tbody>
      </table>
    </div>
  );

  const handleRowExpand = (expanded, record) => {
    const keys = [...expandedRowKeys];
    if (expanded) {
      keys.push(record.key);
    } else {
      const index = keys.indexOf(record.key);
      if (index !== -1) {
        keys.splice(index, 1);
      }
    }
    setExpandedRowKeys(keys);
  };


  const columns = [
    {
      title: 'Medicine Name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      className: 'column-money',
      dataIndex: 'medicineType'
    },
    {
      title: 'Morning',
      dataIndex: 'morning',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => handleChangeCombination(index, 'morning', e.target.checked)}
        >
        </Checkbox>
      )
    },
    {
      title: 'Afternoon',
      dataIndex: 'afternoon',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => handleChangeCombination(index, 'afternoon', e.target.checked)}
        >
        </Checkbox>
      )
    },
    {
      title: 'Evening',
      dataIndex: 'evening',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => handleChangeCombination(index, 'evening', e.target.checked)}
        >
        </Checkbox>
      )
    },
    {
      title: 'Night',
      dataIndex: 'night',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => handleChangeCombination(index, 'night', e.target.checked)}
        >
        </Checkbox>
      )
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: (text, record, index) => (
        <Button
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteCombination(index)}
        />
      )
    }
  ];

  const data = [
    {
      key: '1',
      name: 'John Brown',
      money: '$30,000.00',
      address: 'New York No. 1 Lake Park'
    },
    {
      key: '2',
      name: 'Jim Green',
      money: '$1,256.00',
      address: 'London No. 1 Lake Park'
    },
    {
      key: '3',
      name: 'Joe Black',
      money: '$12,000.00',
      address: 'Sidney No. 1 Lake Park'
    }
  ];


  const handleAddCombinationFromTemplate = () => {
    if (!selectedCourceType) {
      message.error('Please Select Cource Type');
      return;
    }
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    var rheader = {
      method: 'GET',
      headers: {
        //'Authorization': "jwtToken=" + jwtToken,
        'Authorization': jwtToken,
      }
    };
    fetch(process.env.React_App_AWS_API+'api/prescription/' + parameterP + '?useTemplate=true&type=' + selectedCourceType, rheader )
      .then(response => response.json())
      .then(data => {
        // Process the received data here
        console.log("################################9999999999")
        console.log(data);
        if (data.status == false) {

        } else {
          //console.log(JSON.stringify(data.data));
          var medList = data.data.medicines;

          const newCombination = {
            key: combinationList.reduce((maxKey, combination) => Math.max(maxKey, combination.key), 0) + 1,
            name: medicineName,
            notes: combinationNotes,
            instructions: combinationInstructions,
            medicineType: combinationMedicineType,
            uid: combinationUid,
            dosage: combinationDosage,
            morning: combinationMorning,
            afternoon: combinationAfternoon,
            evening: combinationEvening,
            night: combinationBedtime
          };
          /*
          const updatedCombinationList = medList.map(item => ({
            ...newCombination,
            //key: combinationList.reduce((maxKey, combination) => Math.max(maxKey, combination.key), 0) + 1,
            name: item.name,
            notes: item.notes,
            instructions: item.instructions,
            medicineType: item.medicineType,
            uid: item.uid,
            morning: item.morning,
            afternoon: item.afternoon,
            evening: item.evening,
            night: item.night
          }));
          */

          const updatedCombinationList = medList.map((item, index) => ({
            ...item,
            key: combinationList.reduce((maxKey, combination) => Math.max(maxKey, combination.key), 0) + index + 1,
            name: item.name,
            notes: item.notes,
            instructions: item.instructions,
            medicineType: item.medicineType,
            uid: item.uid,
            morning: item.morning,
            afternoon: item.afternoon,
            evening: item.evening,
            night: item.night
          }));
          console.log("######################270################");
          console.log(updatedCombinationList);
          console.log(JSON.stringify(updatedCombinationList));

          console.log("######################--------270------################");
          setCombinationList([...combinationList, ...updatedCombinationList]);
          /*
          const newCombination = {
          key: combinationList.reduce((maxKey, combination) => Math.max(maxKey, combination.key), 0) + 1,
          name: medicineName,
          notes: combinationNotes,
          instructions: combinationInstructions,
          medicineType: combinationMedicineType,
          uid: combinationUid,
          dosage: combinationDosage,
          morning: combinationMorning,
          afternoon: combinationAfternoon,
          evening: combinationEvening,
          night: combinationBedtime
          //bedtime: combinationBedtime
        };
        setCombinationList([...combinationList, newCombination]);

          */
        }


        console.log(data);
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
      });
  }

  const handleAddCombination = () => {
    if (combinationList.length >= 50) {
      message.error('Maximum limit reached');
      return;
    }
    if (!combinationMedicineType) {
      message.error('Please Select Medicine Type');
      return;
    }
    if (!medicineName) {
      message.error('Please select Medicine');
      return;
    }
    //medicineName
    setCombinationCounter(combinationList.length + 1);
    /*
    if(combinationList.length == 0){
      var counterInc = 1; 
    }else{

    }
    */

    const newCombination = {
      key: combinationList.reduce((maxKey, combination) => Math.max(maxKey, combination.key), 0) + 1,
      name: medicineName,
      notes: combinationNotes,
      instructions: combinationInstructions,
      medicineType: combinationMedicineType,
      uid: combinationUid,
      dosage: combinationDosage,
      morning: combinationMorning,
      afternoon: combinationAfternoon,
      evening: combinationEvening,
      night: combinationBedtime
      //bedtime: combinationBedtime
    };
    setCombinationList([...combinationList, newCombination]);

    console.log("######################343################");
    console.log(newCombination);
    console.log(JSON.stringify(newCombination));

    console.log("######################--------343------################");

    // Clear the input values after adding a new diagnosis
    setCombinationName('');
    setCombinationNotes('');
    setCombinationInstructions('');
    setCombinationDosage('');
    setCombinationUid('');

    setCombinationMorning(false);
    setCombinationAfternoon(false);
    setCombinationEvening(false);
    setCombinationBedtime(false);
    //setCombinationMedicineType('');  
    //setMedicineName('');
    //setMedicineInfo([])
  };

  const fetchMedicineInfo = async (medicineType) => {
    try {
      setMedicineInfo([])
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
         //'Authorization': "jwtToken=" + jwtToken,
         'Authorization': jwtToken,
        }
      };
      const response = await fetch(process.env.React_App_AWS_API+'api/medicines?type=' + medicineType, rheader);
      if (response.ok) {
        const data = await response.json();
        console.log('API response:', data); // Check the response data

        // Assuming the response contains the 'data' property with an array of departments
        setMedicineInfo(data.data);
        //setExpandedRowKeys(data.data.medicine[0].uid);
        const uidArray = [];
        data.medicines.forEach((medicine) => {
          uidArray.push(medicine.uid);
          setExpandedRowKeys(uidArray);

        });

      } else {
        console.error('Error fetching departments:', response.status);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const handleCombinationNameChange = (value) => {
    setCombinationName(value);

    setCombinationMedicineType(value);
    setMedicineName('');
    fetchMedicineInfo(value);

    //setMedicineInfo
  };

  const handleMedicineNameChange = (value: any, uid: any) => {
    setMedicineName(value);
    setCombinationUid(uid.uid);
    //alert(JSON.stringify(uid));
    setCombinationInstructions(uid.usage);
  };



  const handleAddExtraMedicine = () => {
    if (extraMedicineList.length >= 10) {
      message.error('Maximum limit reached');
      return;
    }

    if (!extraMedicineName) {
      message.error('Please enter combination');
      return;
    }

    const newExtraMedicine = { name: extraMedicineName, dosage: extraMedicineDosage };
    setExtraMedicineList([...extraMedicineList, newExtraMedicine]);

    // Clear the input values after adding a new diagnosis
    setExtraMedicineName('');
    setExtraMedicineDosage('');

  };


  const handleDeleteCombination = (index) => {
    const updatedCombinationList = [...combinationList];
    updatedCombinationList.splice(index, 1);
    setCombinationList(updatedCombinationList);
  };


  const handleChangeCombinationExt = (index, field, value) => {
    //alert(JSON.stringify(index));
    const newIndex = combinationList.findIndex(combination => combination.key === index);
    const updatedCombinationList = [...combinationList];
    updatedCombinationList[newIndex][field] = value;
    setCombinationList(updatedCombinationList);
    /*
     const updatedCombinationList = [...combinationList];
     updatedCombinationList[index][field] = value;
     setCombinationList(updatedCombinationList);
     */
  };


  const handleChangeCombination = (index, field, value) => {
    //alert(JSON.stringify(index));
    const updatedCombinationList = [...combinationList];
    updatedCombinationList[index][field] = value;
    setCombinationList(updatedCombinationList);
  };




  const handleCaseDescriptionChange = (e) => {
    const { name, value } = e.target;
    setSelectedCourceDuration(value);
  };

  const handleCourseTypeChange = (value) => {
    //const { name, value } = e.target;
    //alert(value);
    setSelectedCourceType(value);
  };

  const handleCourceDurationChange = (value) => {
    //const { name, value } = e.target;
    setSelectedCourceDuration(value);
  };

  const handleInternalNotesChange = (e) => {
    const { name, value } = e.target;
    setSelectedInternalNotes(value);
  };

  const handleAddBoosterMedicine = () => {
    if (boosterMedicineList.length >= 10) {
      message.error('Maximum limit reached');
      return;
    }

    if (!boosterMedicineName) {
      message.error('Please enter booster');
      return;
    }

    const newBoosterMedicine = { name: boosterMedicineName, dosage: boosterMedicineDosage };
    setBoosterMedicineList([...boosterMedicineList, newBoosterMedicine]);

    // Clear the input values after adding a new diagnosis
    setBoosterMedicineName('');
    setBoosterMedicineDosage('');

  };

  const handleDeleteBoosterMedicine = (index) => {
    const updatedBoosterMedicineList = [...boosterMedicineList];
    updatedBoosterMedicineList.splice(index, 1);
    setBoosterMedicineList(updatedBoosterMedicineList);
  };

  const handleChangeBoosterMedicine = (index, field, value) => {
    const updatedBoosterMedicineList = [...boosterMedicineList];
    updatedBoosterMedicineList[index][field] = value;
    setBoosterMedicineList(updatedBoosterMedicineList);
  };


  const successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  const handleSaveCaseSheet = () => {
    console.log("#########################");
    setLoading(true);

    var prescriptionUpdateAction = 'PUT';
    var post_url = process.env.React_App_AWS_API+'api/appointments/' + parameterP + '/prescription';
    var post_object = {
      "staffId": "889100",
      "remarks": "Creating a new prescription from template...",
      "appointmentId": parameterP,
      "prescription": {
        "courseDuration": selectedCourceDuration,
        "courseType": "Acute",
        "entityId": parameterP,
        "notes": [
          "prescription notes"
        ],
        "usageInstructions": [
          "usage instructions"
        ],
        "medicines": combinationList,

      }
    };
    if(prescriptionCreateStatus == '1'){
      prescriptionUpdateAction = 'POST';
      post_url = process.env.React_App_AWS_API+'api/prescription/' + parameterP;
      var post_object_update = {
        "staffId": "889100",
        "remarks": "Creating a new prescription from template...",
        //"appointmentId": parameterP,
        "prescription": {
          "courseDuration": selectedCourceDuration,
          "courseType": "Acute",
          "entityId": parameterP,
          "notes": [
            "prescription notes"
          ],
          "usageInstructions": [
            "usage instructions"
          ],
          "medicines": combinationList,

        }
      };

      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      

      fetch(post_url, {
        method: prescriptionUpdateAction,
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        },
        body: JSON.stringify(post_object_update),
      }) .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response body as JSON
      })
      .then(data => {
        console.log(data); // Access the parsed JSON data
        setLoading(false);
        successAlert(data.message); // Assuming 'data' contains the relevant response data
      })
      .catch(error => {
        // Handle errors here
        console.error(error);
      });


     

    }else{
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      fetch(post_url, {
        method: prescriptionUpdateAction,
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        },
        body: JSON.stringify(post_object),
      })
        .then(response => {
          // Handle the response here
          console.log(response);

          $("#caseSheetUpdateSuccess").show();
          setTimeout(function () {
            $("#caseSheetUpdateSuccess").hide(); // Hide the element after 3 seconds
          }, 3000);

        })
        .catch(error => {
          // Handle errors here
          console.error(error);
        });
    }
    

  };

  return (
    
    <div >
      <div className='row'>
      {contextHolder}
        <Card className='col-md-6' >
          <Meta title="Create From Template" />
          <div className='row'>
            <div className='col'>
              <Form.Item label='Cource Type'>
                <Select  onChange={handleCourseTypeChange}>
                  <Option value='Acute'>Acute</Option>
                  <Option value='Regular'>Regular</Option>
                </Select>
              </Form.Item>
            </div>

            <div className='col'>
              <Button
                shape="circle"
                style={buttonStyle}
                onClick={handleAddCombinationFromTemplate}
                icon={<PlusOutlined className='icon-blue' />} />
            </div>

          </div>

        </Card>
        <Card className='col-md-6' >
          <Meta title="Add Medicine" />
          <div className='row'>
            <div className='col'>
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  {/* <FormItem label='Medicine Type'> */}
                  <Select
                    value={combinationMedicineType}
                    placeholder='Select Type of Medicine'
                    onChange={handleCombinationNameChange}
                    optionFilterProp='children'
                    className='mb-2 mb-md-0'
                  >
                    <Option value='COMBINATION'>Combination</Option>
                    <Option value='BOOSTER'>Booster</Option>
                    <Option value='EXTRA_MEDICINE'>Extra Medicine</Option>
                  </Select>
                  {/* </FormItem>  */}


                </div>

                <div className='col-md-6 col-sm-12'>


                  {/* <FormItem label='Medicine'>  */}
                  <Select
                    value={medicineName}
                    placeholder='Select Medicine'
                    onChange={handleMedicineNameChange}
                    optionFilterProp='children'
                    className='mb-2 mb-md-0'
                  >
                    {medicineInfo.map((medicine) => (
                      <Option key={medicine.name} value={medicine.name} uid={medicine.uid} usage={medicine.usage}>
                        {medicine.name}
                      </Option>
                    ))}

                  </Select>
                  {/* </FormItem>*/}
                </div>


              </div>
            </div>

            <div className='col col-auto'>
              <Button
                shape="circle"
                style={buttonStyle}
                icon={<PlusOutlined className='icon-blue' />}
                onClick={handleAddCombination}
              />
            </div>
          </div>
          <Divider />
        </Card>
      </div>

      <div className='row'>
        <Card className='col-md-12' >
          <Table
            pagination={false}
            columns={columns}
            dataSource={combinationList}
            expandable={{
              expandedRowRender,
              expandedRowKeys,
              onExpand: handleRowExpand,
            }}
            //expandable={{ expandedRowRender }}
            // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
            bordered
            title={() => 'Medicines'}
          //footer={() => 'Footer'}
          />
        </Card>
      </div>

      <div className='row'>

        {/* <Card className='col-md-5 col-sm-12'  >
          <Input
            placeholder="Cource Type"
            value={selectedCourceType}
            onChange={handleCourseTypeChange} id='courceType'
          />
        </Card> */}
        <Card className='col-md-2 col-sm-12'>

        </Card>

        <Card className='col-md-5 col-sm-12'  >
          <Meta title="Cource Duration" />

          <Select
                    value={selectedCourceDuration}
                    placeholder='Select Duration'
                    onChange={handleCourceDurationChange}
                    optionFilterProp='children'
                    className='mb-2 mb-md-0'
                  >
                    <Option value='3 Days'>3 Days</Option>
                    <Option value='7 Days'>7 Days</Option>
                    <Option value='1 Month'>1 Month</Option>
                    <Option value='2 Months'>2 Months</Option>
                    <Option value='3 Months'>3 Months</Option>
                    <Option value='6 Months'>6 Months</Option>
                  </Select>


          {/* <Input
            placeholder="Cource Duration"
            value={selectedCourceDuration}
            onChange={handleCourceDurationChange} id='courceType'
          /> */}

        </Card>

      </div>

      <div>
        <Button loading={loading} type='primary' onClick={() => handleSaveCaseSheet()} >Save Changes</Button>

        <div id='caseSheetUpdateSuccess' style={{ display: "none" }}>
          <Alert type='success' message='Saved Successfully' />
          <br></br>
        </div>
      </div>

    </div>
  );
};

export default CaseSheetTabPrescription;