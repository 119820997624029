import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Divider, message, Card, Checkbox, Form, Alert, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import $ from 'jquery';
import Cookies from 'universal-cookie';
const { Meta } = Card;
const FormItem = Form.Item;
const { Option } = Select;


const CaseSheetTabCaseSheet = ({ parameterP }) => {
  const [diseases, setDiseases] = useState([]);
  const buttonStyle = { background: 'white', border: 'none' };
  const [loading, setLoading] = useState(false);
  const [diseaseInput, setDiseaseInput] = useState('');
  const [sinceWhenInput, setSinceWhenInput] = useState('');
  const [selectedCaseDescription, setSelectedCaseDescription] = useState('');
  const [selectedInternalNotes, setSelectedInternalNotes] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const [diagnosisList, setDiagnosisList] = useState([]);
  const [diseasesMetaList, setDiseasesMetaList] = useState([]);
  const [diseaseKeyValueObject, setDiseaseKeyValueObject] = useState<any>([]);


  

  
  const [diagnosisInput, setDiagnosisInput] = useState('');
  const [diagnosisInputVal, setDiagnosisInputVal] = useState('');
  
  const [treatingInput, setTreatingInput] = useState(false);


  const handleAdd = () => {
    if (diseases.length >= 10) {
      message.error('Maximum limit reached');
      return;
    }

    if (!diseaseInput || !sinceWhenInput) {
      message.error('Please enter both disease and since when');
      return;
    }

    const newDisease = { sympton: diseaseInput, duration: sinceWhenInput,notes:"notes" };
    setDiseases([...diseases, newDisease]);

    // Clear the input values after adding a new disease
    setDiseaseInput('');
    setSinceWhenInput('');
  };

  
  useEffect(() => {
    const fetchCaseSheetData = async (appointmentId) => {
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        }
      };
      fetch(process.env.React_App_AWS_API+'api/appointments/'+parameterP+'/casesheet', rheader)
        .then(response => response.json())
        .then(data => {
          // Process the received data here
          console.log("################################9999999999")
          console.log(data);
          if(data.status == false){
           
          }else{
            setDiseases(data.data.symptoms);
            setDiagnosisList(data.data.diagnosis);
            setSelectedCaseDescription(data.data.caseDescription);
            setSelectedInternalNotes(data.data.notes)
          }
         

          console.log(data);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error(error);
        });

    };

    const fetchDiseaseList = async () => {
    


      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        }
      };

      fetch(process.env.React_App_AWS_API+'api/metadata/diseases',rheader)
        .then(response => response.json())
        .then(data => {
          // Process the received data here
          console.log("################################9999999999")
          console.log(data);
          if(data.status == false){
           
          }else{
            setDiseasesMetaList(data.data);
            console.log(JSON.stringify(data.data));


              const keyValueData = Object.entries(data.data).map(([key, value]) => ({
                key,
                value: (value as any).name
              }));

              setDiseaseKeyValueObject(keyValueData);
              console.log("#### 104  ######");
              console.log(JSON.stringify(keyValueData));

            // const options = Object.values(diseasesMetaList).map(disease => (
            //   <Option key={disease.name} value={disease.name}>
            //     {disease.name}
            //   </Option>
            // ));

           
          }
         

          console.log(data);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error(error);
        });







    };
    fetchCaseSheetData(parameterP);
    fetchDiseaseList();
  }, []);


  const handleAddDiagnosis = () => {
    if (diagnosisList.length >= 10) {
      message.error('Maximum limit reached');
      return;
    }

    if (!diagnosisInput) {
      message.error('Please enter both diagnosis name and treating status');
      return;
    }

    const newDiagnosis = { disease: diagnosisInput, treatmentOn: treatingInput };
    setDiagnosisList([...diagnosisList, newDiagnosis]);

    // Clear the input values after adding a new diagnosis
    setDiagnosisInput('');
    setTreatingInput(false);
  };

  const handleDelete = (index) => {
    const updatedDiseases = [...diseases];
    updatedDiseases.splice(index, 1);
    setDiseases(updatedDiseases);
  };

  const handleDeleteDiagnosis = (index) => {
    const updatedDiagnosisList = [...diagnosisList];
    updatedDiagnosisList.splice(index, 1);
    setDiagnosisList(updatedDiagnosisList);
  };

  const handleChange = (index, field, value) => {
    const updatedDiseases = [...diseases];
    updatedDiseases[index][field] = value;
    setDiseases(updatedDiseases);
  };

  const handleChangeDiagnosis = (index, field, value) => {
    const updatedDiagnosisList = [...diagnosisList];
    updatedDiagnosisList[index][field] = value;
    setDiagnosisList(updatedDiagnosisList);
  };

  const handleCaseDescriptionChange = (e) => {
    const { name, value } = e.target;
    setSelectedCaseDescription(value);
  };

  const handleDiseaseNameChange = (value:any, disvalue:any) => {
    setDiagnosisInput(value);
    setDiagnosisInputVal(disvalue);
   };

 

 

  const handleInternalNotesChange = (e) => {
    const { name, value } = e.target;
    setSelectedInternalNotes(value);
  };

  const successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  
  const handleSaveCaseSheet = () => {
    setLoading(true);
    console.log("###################3");
    console.log(JSON.stringify(diagnosisList));
    console.log("-------")
    console.log(JSON.stringify(diseases));
    

    console.log("#########################");
    
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');

    fetch(process.env.React_App_AWS_API+'api/appointments/'+parameterP+'/casesheet', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "jwtToken=" + jwtToken,
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        staffId: '517016',
        remarks: 'remarks',
        casesheet: {
          symptoms: diseases,
          caseDescription: selectedCaseDescription,
          notes: selectedInternalNotes,
          diagnosis: diagnosisList,
          attachments: [],
        },
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response body as JSON
    })
    .then(data => {
      console.log(data); // Access the parsed JSON data
      setLoading(false);
      successAlert(data.message); // Assuming 'data' contains the relevant response data
    })
    .catch(error => {
      // Handle errors here
      console.error(error);
    });



  };

  

  return (
    
    <div >
        {contextHolder} 
      <div className='row'>
        <Card className='col-md-5'  >
          <Meta
            title="Symptoms and Duration"
          />
          <div className='row'>
            <div className='col'>
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <Input

                    placeholder="Disease"
                    value={diseaseInput}
                    onChange={(e) => setDiseaseInput(e.target.value)}
                  />
                </div>

                <div className='col-md-6 col-sm-12'>
                  <Input

                    placeholder="Since When"
                    value={sinceWhenInput}
                    onChange={(e) => setSinceWhenInput(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className='col col-auto'>
              <Button
                shape="circle"
                style={buttonStyle}
                icon={<PlusOutlined className='icon-blue' />}
                onClick={handleAdd}
              />
            </div>
          </div>

          <Divider />

          {diseases.map((disease, index) => (
            <div className='row mb-10' style={{ marginBottom: "10px" }} key={index}>
              <div className='col'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <Input

                      placeholder="Disease"
                      value={disease.sympton}
                      onChange={(e) => handleChange(index, 'sympton', e.target.value)}
                    />
                  </div>

                  <div className='col-md-6 col-sm-12'>
                    <Input

                      placeholder="Since When"
                      value={disease.duration}
                      onChange={(e) => handleChange(index, 'duration', e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className='col col-auto'>
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(index)}
                />
              </div>
            </div>
          ))}
        </Card>
        <Card className='col-md-2' >
        </Card>
        <Card className='col-md-5' >
          <Meta title="Diagnosis" />
          <div className='row'>
            <div className='col'>
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  {/* <Input
                    placeholder="Diagnosis Name"
                    value={diagnosisInput}
                    onChange={(e) => setDiagnosisInput(e.target.value)}
                  /> */}





                  <Select 
                 onChange={handleDiseaseNameChange}
                  style={{ width: 200 }}>
                    {diseaseKeyValueObject.map(item => (
                      <Option key={item.key} value={item.key} disvalue={item.value}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>


                </div>

                <div className='col-md-6 col-sm-12'>
                  <Checkbox
                    checked={treatingInput}
                    onChange={(e) => setTreatingInput(e.target.checked)}
                  >
                    Treating?
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className='col col-auto'>
              <Button
                shape="circle"
                style={buttonStyle}
                icon={<PlusOutlined className='icon-blue' />}
                onClick={handleAddDiagnosis}
              />
            </div>
          </div>

          <Divider />

          {diagnosisList.map((diagnosis, index) => (
            <div className='row mb-10' style={{ marginBottom: "10px" }} key={index}>
              <div className='col'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <Input
                      placeholder="Diagnosis Name"
                      value={diagnosis.disease}
                      onChange={(e) => handleChangeDiagnosis(index, 'disease', e.target.value)}
                    />
                  </div>

                  <div className='col-md-6 col-sm-12'>
                    <Checkbox
                      checked={diagnosis.treatmentOn}
                      onChange={(e) => handleChangeDiagnosis(index, 'treatmentOn', e.target.checked)}
                    >
                      Treating?
                    </Checkbox>
                  </div>
                </div>
              </div>

              <div className='col col-auto'>
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteDiagnosis(index)}
                />
              </div>
            </div>
          ))}
        </Card>



      </div>

      <div className='row'>
       
          <Card className='col-md-5 col-sm-12'  >
          <Meta title="Doctors Description" />
              <Input.TextArea
                value={selectedCaseDescription}
                onChange={handleCaseDescriptionChange} id='caseDescription'
                placeholder='Case Description' rows={4} />
           

          </Card>
          <Card className='col-md-2 col-sm-12'>

          </Card>

          <Card className='col-md-5 col-sm-12'  >
          <Meta title="Internal Notes" />
              <Input.TextArea
                value={selectedInternalNotes}
                onChange={handleInternalNotesChange} id='internalNotes'
                placeholder='Internal Notes' rows={4} />
            
          </Card>
        
      </div>

      <div>
      <Button loading={loading} type='primary' onClick={() => handleSaveCaseSheet()} >Save Changes</Button>

      <div id='caseSheetUpdateSuccess' style={{ display: "none" }}>
            <Alert type='success' message='Saved Successfully' />
            <br></br>
          </div>
        </div>
      
    </div>









  );
};

export default CaseSheetTabCaseSheet;
