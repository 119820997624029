import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select, Timeline, Alert, Modal, DatePicker, DatePickerProps, TimePicker, message, Space } from 'antd';
import axios from 'axios';
import $ from 'jquery';
import { useFormik } from 'formik';
import { IPageData } from '../../interfaces/page';
import { usePageData } from '../../hooks/usePage';
import { useGetPatient } from '../../hooks/useGetPatient';
import { useGetBillings } from '../../hooks/useGetBillings';
import ImageLoader from '../../layout/components/patients/ImageLoader';
import BillingTable from './components/BillingTable';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'universal-cookie';
import services from '../../services/services';


const pageData: IPageData = {
  title: 'Appointment Detail page',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'Doctors',
      route: 'default-dashboard'
    },
    {
      title: 'Liam Jouns'
    }
  ]
};

const FormItem = Form.Item;
const Option = Select.Option;

const ProfileForm = ({ patient }) => {
  const { values } = useFormik({
    initialValues: { ...patient },
    onSubmit: () => null
  });

  return (
    <Form layout='vertical'>
      <FormItem label='Full name'>
        <Input defaultValue={values.fullName} placeholder='Full name' />
      </FormItem>

      <FormItem label='Id'>
        <Input defaultValue={values.id} placeholder='Id' />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Age'>
            <Input defaultValue={values.age} placeholder='Age' />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Gender'>
            <Select defaultValue={values.gender} placeholder='Gender'>
              <Option value='male'>Male</Option>
              <Option value='female'>Female</Option>
            </Select>
          </FormItem>
        </div>
      </div>

      <FormItem label='Phone'>
        <Input defaultValue={values.phone} placeholder='Phone' />
      </FormItem>

      <FormItem label='Address'>
        <Input.TextArea rows={4} defaultValue={values.address} placeholder='Address' />
      </FormItem>

      <FormItem label='Last visit'>
        <Input defaultValue={values.lastVisit.split('T')[0]} placeholder='Last visit' readOnly />
      </FormItem>

      <FormItem label='Status'>
        <Select defaultValue={values.status} placeholder='Status'>
          <Option value='Approved'>Approved</Option>
          <Option value='Pending'>Pending</Option>
        </Select>
      </FormItem>
    </Form>
  );
};

const PatientTimeline = () => {
  const timelineItems = [
    {
      color: 'red',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>New prescription</h4>
          <span className='text-base text-color-100'>Now</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula
            ut id elit.
          </span>
        </div>
      )
    },
    {
      color: 'blue',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Appointment</h4>
          <span className='text-base text-color-100'>2m ago</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'yellow',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Medication</h4>
          <span className='text-base text-color-100'>2h ago</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'pink',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Operation</h4>
          <span className='text-base text-color-100'>15h ago</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehiculaut id elit.
          </span>
        </div>
      )
    },
    {
      color: 'blue',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>New patient</h4>
          <span className='text-base text-color-100'>Jul 10</span>
          <span className='text-base'>Lorem ipsum dolor sit.</span>
        </div>
      )
    },
    {
      color: 'red',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Examination</h4>
          <span className='text-base text-color-100'>Jul 11</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'green',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Re-Examination</h4>
          <span className='text-base text-color-100'>Jul 25</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehiculaut id elit.
          </span>
        </div>
      )
    }
  ];

  return (
    <Timeline items={timelineItems} />
  )
};


const AppointmentDetailPage = ({ parameterP }) => {
  const [loading, setLoading] = useState(false);

  const [doctorsStaff, setDoctorStaff] = useState<any>([]);
  const [nonDoctosStaff, setNonDoctorsStaff] = useState<any>([]);

  
  const { patient } = useGetPatient('Liam');
  const billings = useGetBillings();
  const [appointmentInfo, setAppointmentInfo] = useState<any>({});
  const [readAppointmentId, setReadAppointmentId] = useState<any>('');
  const [phone, setPhone] = useState<any>('');
  const [selectedFilterQueryType, setSelectedFilterQueryType] = useState('');
  const [departments, setDepartments] = useState<any>([]);
  const [staffInfo, setStaffInfo] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>([]);
  const [timelineItemsDetails, setTimelineItemsDetails] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [prevAppointmentStatus, setPrevAppointmentStatus] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');
  const [selectedRemarks, setSelectedRemarks] = useState('');
  const [selectedConfirmationBy, setSelectedConfirmationBy] = useState('');
  const [selectedConsultationBy, setSelectedConsultationBy] = useState('');
  const [selectedAssignedTo, setSelectedAssignedTo] = useState('');

  const [addingModalVisibility, setAddingModalVisibility] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState<any>('');
  const [invoiceDateInput, setInvoiceDateInput] = useState<any>('');
  
  const [invoiceLink, setInvoiceLink] = useState<any>('');
  const [invoiceAmount, setInvoiceAmount] = useState<any>('');
  const [invoiceId, setInvoiceId] = useState<any>('');

  const [paymentDate, setPaymentDate] = useState<any>('');
  const [paymentStatus, setPaymentStatus] = useState<any>('');
  const [paymentType, setPaymentType] = useState<any>('');
  const [paymentAmount, setPaymentAmount] = useState<any>('');


  const [deliveryDeliveredBy, setDeliveryDeliveredBy] = useState<any>('');
  const [deliveryCourierName, setDeliveryCourierName] = useState<any>('');
  const [deliveryPickupTime, setDeliveryPickupTime] = useState<any>('');
  const [deliveryStatus, setDeliveryStatus] = useState<any>('');
  const [deliveryTrackingLink, setDeliveryTrackingLink] = useState<any>('');



  const [appointmentDate, setAppointmentDate] = useState<any>('');
  const [activeDiv, setActiveDiv] = useState('');
  const [appointmentDisplayStatus, setAppointmentDisplayStatus] = useState('');
  const statusOptions = [
    { value: "ASSIGNED", label: "TO_ASSIGNED" },
    { value: "Assign to Doctor", label: "TO_DOCTOR" },
    { value: "Send to Confirmation", label: "TO_CONFIRMATION" },
    { value: "Send to Accounts", label: "TO_ACCOUNTS" },
    { value: "Invoice Created", label: "TO_INVOICE" },
    { value: "Send To Pharmacy", label: "TO_PHARMACY" },
    { value: "Send To Dispatch", label: "TO_DISPATCH" },
    { value: "Send to Delivery", label: "TO_DELIVERY" },
    { value: "Reschedule", label: "TO_RESCHEDULE" },
    { value: "Cancel", label: "TO_CANCEL" },
    { value: "Patient Declined", label: "TO_DECLINE" },
    { value: "CLOSED", label: "TO_CLOSED" },
  ];


  // const parameterP = searchParams.get('p');
  //
  console.log(parameterP);

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
    setPhone(value);
  };



  //
  const getStatusValue = (label) => {
    const statusOption = statusOptions.find((option) => option.label === label);
    return statusOption ? statusOption.value : null;
  };

  const handleShowAddAppointment = (readAppointmentId, status) => {
    console.log("readAppointmentId-->" + readAppointmentId);
    console.log("status--" + status)
    //setAppPatientPrimaryKey(patient.PatientPrimaryKey)
    //setAppPatientName(patient.name);
    //setAppPatientID(patient.registration_id)
    setAddingModalVisibility(true);
    setAppointmentDisplayStatus(status)
    $('.modalClass').hide();

    if (status == "TO_RESCHEDULE") {
      setActiveDiv("RESCHEDULED_ID");
      setAppointmentDisplayStatus("RESCHEDULED");
    } else if (status == "TO_ASSIGNED") {
      setActiveDiv("ASSIGNED_ID");
      setAppointmentDisplayStatus("Assign to Staff");
    } else if (status == "TO_DOCTOR") {
      setActiveDiv("TO_DOCTOR_ID");
      setAppointmentDisplayStatus("Assign to Doctor");
    } else if (status == "TO_CANCEL") {
      setActiveDiv("CANCELLED_ID");
      setAppointmentDisplayStatus("Cancel Appointment");
    } else if (status == "TO_CONFIRMATION") {
      setActiveDiv("IN_CONFIRMATION_ID");
      setAppointmentDisplayStatus("Send to Confirmation");
    } else if (status == "TO_INVOICE") {
      setActiveDiv("TO_INVOICE_ID");
      setAppointmentDisplayStatus("Invoice Created");
    } else if (status == "TO_ACCOUNTS") {
      setActiveDiv("TO_ACCOUNTS_ID");
      setAppointmentDisplayStatus("Send to Accounts");
    } else if (status == "TO_PHARMACY") {
      setActiveDiv("TO_PHARMACY_ID");
      setAppointmentDisplayStatus("Send to Pharmacy");
    } else if (status == "CONFIRMED") {
      setActiveDiv("CONFIRMED_ID");
      setAppointmentDisplayStatus("Send to Accounts");
    } else if (status == "AWAITING PAYMENT") {
      setActiveDiv("AWAITING_PAYMENT_ID");
      setAppointmentDisplayStatus("Invoice Created");
    } else if (status == "PAYMENT RECEIVED") {
      setActiveDiv("PAYMENT_RECEIVED_ID");
      setAppointmentDisplayStatus("Payment Details");
    } else if (status == "IN_PHARMACY_ID") {
      setActiveDiv("IN PHARMACY");
      setAppointmentDisplayStatus("Send to Pharmacy");
    } else if (status == "TO_DISPATCH") {
      setActiveDiv("TO_DISPATCH_ID");
      setAppointmentDisplayStatus("Send to Dispatch");
    } else if (status == "TO_DELIVERY") {
      setActiveDiv("TO_DELIVERY_ID");
      setAppointmentDisplayStatus("Send to Delivery");
    }








  };
  const openAddingModal = () => setAddingModalVisibility(true);
  const closeAddingModal = () => setAddingModalVisibility(false);

  const handleChangeDeliveryDeliveredBy = (e) => {
    const { name, value } = e.target;
    setDeliveryDeliveredBy(value);
  };

  const handleChangeDeliveryCourierName = (e) => {
    const { name, value } = e.target;
    setDeliveryCourierName(value);
  };

  const handleChangeDeliveryPickupTime = (value) => {
    //const { name, value } = e.target;
    setDeliveryPickupTime(value);
  };

  const handleChangeDeliveryStatus = (e) => {
    const { name, value } = e.target;
    setDeliveryStatus(value);
  };

  const handleChangeDeliveryTrackingLink = (e) => {
    const { name, value } = e.target;
    setDeliveryTrackingLink(value);
  };


  const handlePaymentDateChange = (value) => {
    //const { name, value } = e.target;
    setPaymentDate(value);
  };

  const handlePaymentStatusChange = (e) => {
    const { name, value } = e.target;
    setPaymentStatus(value);
  };

  const handlePaymentTypeChange = (e) => {
    const { name, value } = e.target;
    setPaymentType(value);
  };

  const handlePaymentAmountChange = (e) => {
    const { name, value } = e.target;
    setPaymentAmount(value);
  };

  const handleDateChange = (value) => {
    //const { name, value } = e.target;
    setAppointmentDate(value);
  };

  const handleInvoiceDateChange = (value) => {
    //const { name, value } = e.target;
    //alert(value);
    const formattedDate = value ? value.toISOString() : '';

    setInvoiceDateInput(formattedDate);
    //alert(invoiceDateInput);
  };

  // const handleInvoiceDateChange2 = (value) => {
  //   //const { name, value } = e.target;
  //  // alert("value-"+value);
  //   setInvoiceDate(value);
  // };

  
  const handleInvoiceLinkChange = (e) => {
    const { name, value } = e.target;
    setInvoiceLink(value);
  };

  const handleInvoiceAmountChange = (e) => {
    const { name, value } = e.target;
    setInvoiceAmount(value);
  };

  const handleInvoiceIdChange = (e) => {
    const { name, value } = e.target;
    setInvoiceId(value);
  };
  //

  const handleRemarksChange = (e) => {
    const { name, value } = e.target;
    setSelectedRemarks(value);
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
  };
  const handleAssignedToChange = (value) => {
    setSelectedAssignedTo(value);
  };


  const handleStaffChange = (value) => {
    setSelectedStaff(value);
  };
  const handleConfirmationByChange = (value) => {
    setSelectedConfirmationBy(value);
  };

  const handleConsultationByChange = (value) => {
    setSelectedConsultationBy(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };
  /*
  const formatDate = (indate) => {
    if (indate == "") {
      return "";
    }
    const date = moment(indate, 'DDMMYYYY');
    const beautifulDate = date.format('MMMM DD, YYYY');
    return beautifulDate;
  };
  */
  const formatDate = (indate) => {
    if (!indate || indate === "") {
      return "";
    }

    let date;

    if (typeof indate === "string" && indate.indexOf("-") !== -1) {
      // Handle ISO 8601 format: 2023-06-25T23:03:37.211Z
      date = moment(indate);
    } else {
      // Assume DDMMYYYY format: 25062023
      date = moment(indate, 'DDMMYYYY');
    }

    const beautifulDate = date.format('MMMM DD, YYYY');
    return beautifulDate;
  };

  const successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };
  const updateAppointment = async (appointmentId, data) => {
    setLoading(true);
    const url = process.env.React_App_AWS_API +'api/appointments/' + appointmentId;
    console.log("test 406");
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        },
        body: JSON.stringify(data)
      });

      if (response) {
        const responseData = await response.json();
        console.log(responseData);
        //setLoading(false);
        setLoading(false);
        //alert("test");
        //alert(responseData.message)
        successAlert(responseData.message);
        //$("#appointMentCreateSuccess").show();
        
         setTimeout(() => {
           window.location.reload();
         }, 2000);
        
         
        
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAppointmentinPart = async (appointmentId, customStatusOperation) => {

    try {
      const url = process.env.React_App_AWS_API+'api/appointments/' + appointmentId;

      const headers = {
        'Content-Type': 'application/json',
      };

      switch (customStatusOperation) {
        case 'TO_RESCHEDULE':
          var data_reschedule = {
            "staffId": "165724",
            "statusCode": "TO_RESCHEDULE",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": "165724",
              //"consultationBy": "165724",
              //"confirmationBy": "534057", 
              "appointmentDate": appointmentDate,
              "remarks": "Reschedule"
            }
          };
          /*
          var response = await axios.patch(url, data_reschedule, { headers });
          console.log(response.data);
          */
          updateAppointment(appointmentId, data_reschedule);

          break;
        case 'TO_ASSIGNED':
          var data_assign = {
            "staffId": "165724",
            "statusCode": "TO_ASSIGNED",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              //"consultationBy": "165724",
              //"confirmationBy": "534057", 
              //"appointmentDate": "Mahesn",
              "remarks": "Assigned to staff"
            }
          };
          updateAppointment(appointmentId, data_assign);
          /*
          var response = await axios.patch(url, data_assign, { headers });
          console.log(response.data);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          */
          break;
        case 'TO_DOCTOR':
          var data_to_doctor = {
            "staffId": "165724",
            "statusCode": "TO_DOCTOR",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "consultationBy": selectedAssignedTo,
              //"confirmationBy": "534057", 
              //"appointmentDate": "Mahesn",
              "remarks": "Assigned to doctor"
            }
          };
          updateAppointment(appointmentId, data_to_doctor);
          /*
          var response = await axios.patch(url, data_to_doctor, { headers });
          console.log(response.data);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          */
          break;

        case 'TO_CONFIRMATION':
          var data_to_confirmation = {
            "staffId": "165724",
            "statusCode": "TO_CONFIRMATION",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              //"consultationBy": selectedAssignedTo,
              "confirmationBy": selectedAssignedTo,
              //"appointmentDate": "Mahesn",
              "remarks": "Sent to confirmation"
            }
          };
          updateAppointment(appointmentId, data_to_confirmation);
          /*
          var response = await axios.patch(url, data_to_confirmation, { headers });
          console.log(response.data);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          */
          break;

        case 'TO_DISPATCH':
          var data_to_dispatch = {
            "staffId": "165724",
            "statusCode": "TO_DISPATCH",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              //"consultationBy": selectedAssignedTo,
              //"confirmationBy": selectedAssignedTo,
              //"appointmentDate": "Mahesn",
              "remarks": "Sent to dispatch"
            }
          };
          updateAppointment(appointmentId, data_to_dispatch);

          break;

        case 'TO_DELIVERY':
          var data_to_delivery = {
            "staffId": "165724",
            "statusCode": "TO_DISPATCH",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "deliveredBy": deliveryDeliveredBy,
              "courierName": deliveryCourierName,
              "pickupTime": deliveryPickupTime,
              "status": deliveryStatus,
              "trackingLink": deliveryTrackingLink,
              "remarks": "Delivery"
            }
          };
          updateAppointment(appointmentId, data_to_delivery);

          break;

        case 'TO_INVOICE':
         
          var data_to_invoice = {
            "staffId": "165724",
            "statusCode": "TO_INVOICE",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "invoiceDate": invoiceDateInput,
              "invoiceLink": invoiceLink,
              "invoiceAmount": invoiceAmount,
              "invoiceId": invoiceId,
              "remarks": "Sent to Invoice"
            }
          };
          //console.log("7777777777")
          //console.log(JSON.stringify(data_to_invoice))
         updateAppointment(appointmentId, data_to_invoice);
          /*
           var response = await axios.patch(url, data_to_invoice, { headers });
           console.log(response.data);
           setTimeout(() => {
             window.location.reload();
           }, 3000);
           */
          break;
        case 'TO_PHARMACY':
          var data_to_pharmacy = {
            "staffId": "165724",
            "statusCode": "TO_PHARMACY",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "paymentStatus": paymentStatus,
              "paymentType": paymentType,
              "paymentDate": paymentDate,
              "paymentAmount": paymentAmount,
              "invoiceId": invoiceId,
              "remarks": "Sent to Pharmacy"
            }
          };
          updateAppointment(appointmentId, data_to_pharmacy);

          /*
          console.log(data_to_pharmacy);
          console.log("528");
          try{
            var response = await axios.patch(url, data_to_pharmacy, { headers });
            console.log(response.data);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }catch(ex){
            console.log(ex)
          }
          */



          break;
        case 'TO_ACCOUNTS':
          var data_to_accounts = {
            "staffId": "165724",
            "statusCode": "TO_ACCOUNTS",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              //"consultationBy": selectedAssignedTo,
              //"confirmationBy": selectedAssignedTo, 
              //"appointmentDate": "Mahesn",
              "remarks": "Sent to accounts"
            }
          };
          updateAppointment(appointmentId, data_to_accounts);
          /*
          var response = await axios.patch(url, data_to_accounts, { headers });
          console.log(response.data);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          */
          break;

      }




      // const data = {
      //   staffId: selectedStaff,
      //   change: setPrevAppointmentStatus,
      //   appointment: {
      //     status: selectedStatus,
      //     assignedTo: selectedAssignedTo,
      //     consultationBy: selectedConsultationBy,
      //     staffId: selectedStaff,
      //     remarks: selectedRemarks,
      //     confirmationBy: selectedConfirmationBy,
      //     department: selectedDepartment
      //   },
      // };



      $("#PatientAppointmentFormSuccess").show();
      setTimeout(function () {
        $("#PatientAppointmentFormSuccess").hide(); // Hide the element after 3 seconds
      }, 3000);
    } catch (error) {
      console.error(error);
    }

  };

  const handleAppointmentin = async (appointmentId) => {

    try {
      const url = process.env.React_App_AWS_API+'api/appointments/' + appointmentId;

      const headers = {
        'Content-Type': 'application/json',
      };

      const data = {
        staffId: selectedStaff,
        change: setPrevAppointmentStatus,
        appointment: {
          status: selectedStatus,
          assignedTo: selectedAssignedTo,
          consultationBy: selectedConsultationBy,
          staffId: selectedStaff,
          remarks: selectedRemarks,
          confirmationBy: selectedConfirmationBy,
          department: selectedDepartment
        },
      };

      const response = await axios.patch(url, data, { headers });
      console.log(response.data);

      $("#PatientAppointmentFormSuccess").show();
      setTimeout(function () {
        $("#PatientAppointmentFormSuccess").hide(); // Hide the element after 3 seconds
      }, 3000);
    } catch (error) {
      console.error(error);
    }

  };
  useEffect(() => {
    $("#PatientAppointmentFormSuccess").hide();

    const fetchDepartmentsData = async () => {
      try {
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };
        const response = await fetch(process.env.React_App_AWS_API+'api/departments',rheader);
        if (response.ok) {
          const data = await response.json();
          console.log('API response:', data); // Check the response data

          // Assuming the response contains the 'data' property with an array of departments
          setDepartments(data.data);
        } else {
          console.error('Error fetching departments:', response.status);
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };
    fetchDepartmentsData();

    const fetchStaffData = async () => {
      try {
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };
        //alert(jwtToken)
        const response = await fetch(process.env.React_App_AWS_API+'api/staff',rheader);
        if (response.ok) {
          const data = await response.json();
          console.log('API response:', data); // Check the response data

          // Assuming the response contains the 'data' property with an array of departments
          setStaffInfo(data.data);

          const doctors = data.data.filter(user => user.department === 'Doctor');
          const nonDoctors = data.data.filter(user => user.department !== 'Doctor');

          setDoctorStaff(doctors);
          setNonDoctorsStaff(nonDoctors);



        } else {
          console.error('Error fetching departments:', response.status);
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };
    fetchStaffData();

    const fetchData = async (appointmentId) => {
      $("#PatientAppointmentFormSuccess").hide();
      setReadAppointmentId(parameterP);
      try {
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };
       
        const response = await fetch(process.env.React_App_AWS_API+'api/appointments?id=' + appointmentId,rheader);
        const data = await response.json();
        //var p_data = data.data[0];
        var p_data = data.data
        setPhone(p_data.phone);
        setSelectedDepartment(p_data.department);
        setAppointmentInfo(p_data);


        try {

          services.getWorkFlow(p_data.statusCode).then((statusResponse) => {

            if (statusResponse) {
               console.log(JSON.stringify(statusResponse));
               setStatuses(statusResponse.data.data);
               setSelectedStatus(p_data.status);
               setPrevAppointmentStatus(p_data.status);
               setSelectedStaff(p_data.staffId);
               setSelectedRemarks(p_data.remarks)
               setSelectedConfirmationBy(p_data.confirmationBy);
               setSelectedConsultationBy(p_data.consultationBy);
               setSelectedAssignedTo(p_data.assignedTo);
              
      
            }
      
         });
         /*
          const statusResponse = await axios.get(process.env.React_App_AWS_API+'api/appointments/' + p_data.statusCode + '/workflow');
          setStatuses(statusResponse.data.data);
          setSelectedStatus(p_data.status);
          setPrevAppointmentStatus(p_data.status);
          setSelectedStaff(p_data.staffId);
          setSelectedRemarks(p_data.remarks)
          setSelectedConfirmationBy(p_data.confirmationBy);
          setSelectedConsultationBy(p_data.consultationBy);
          setSelectedAssignedTo(p_data.assignedTo);
          */
        } catch (error) {
          console.error('Error fetching workflow:', error);
        }


      } catch (error) {
        console.error(error);
      }
    };
    fetchData(parameterP);

    const fetchDataTimeLine = async (appointmentId) => {
      $("#PatientAppointmentFormSuccess").hide();
      setReadAppointmentId(parameterP);
      try {

        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };

        const response = await fetch(process.env.React_App_AWS_API+'api/appointments/' + appointmentId + '/timeline',rheader);
        const result = await response.json();
        console.log("591");
        console.log(result);
        var colorarr = ["red", "blue", "yellow", "pink", "blue", "red", "green"];


        const timelineItems = result.data.map(item => {
          let color = '';
          //let timeAgo = moment(item.timestamp).fromNow();
          let timeAgo = moment(item.timestamp).format('MMMM Do, YYYY h:mm A');
          let description = '';
          let updatedUser = item.updatedUser;

          //color = 'blue';
          //timeAgo = 'Now';
          description = item.description;

          let randomIndex = Math.floor(Math.random() * colorarr.length);
          color = colorarr[randomIndex];

          /*
          switch (item.change) {
            case 'CREATED':
              color = 'blue';
              timeAgo = 'Now';
              description = item.description;
              break;
            case 'Assigned':
              color = 'red';
              timeAgo = '2m ago';
              description = item.description;
              break;
            // Add more cases as needed for other "change" values

            default:
              break;
          }*/

          return {
            color,
            children: (
              <div className='d-flex flex-column'>
                <h4 className='m-0'>{item.change}</h4>
                <span className='text-base text-color-100'>{timeAgo} by {updatedUser}</span>
                <span className='text-base'>{description}</span>
              </div>
            )
          };
        });
        setTimelineItemsDetails(timelineItems);
        console.log(timelineItems);



      } catch (error) {
        console.error(error);
      }
    };
    fetchDataTimeLine(parameterP);

  }, []);

  usePageData(pageData);

  return (
    
    patient && (
      <>
         {contextHolder}
        <div className='row mb-4'>
          <div className='col-md-6 col-sm-12'>
            <div className='info stack'>

              <Form layout='vertical'>

                <Form.Item label='Registration ID'>
                  <Input disabled type='text' id='registration_id' value={appointmentInfo.registrationId + ' (' + appointmentInfo.patientName + ')'} placeholder='Registration ID' />
                </Form.Item>



                <FormItem label='Appointment Date'>
                  <Input type='text' id='appointmentDate' value={formatDate(appointmentInfo.appointmentDate)} placeholder='Full name' />

                </FormItem>

                <FormItem label='Assigned To'>
                  <Select disabled
                    id="assignedTo"
                    value={selectedAssignedTo}
                    onChange={handleAssignedToChange}
                    placeholder="Select Assigned To"
                  >
                    {staffInfo.map((staff) => (
                      <Option key={staff.name} value={staff.loginId}>
                        {staff.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem label='Appointment Status'>
                  <Select
                    disabled
                    id="status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    placeholder="Select Status"
                  >
                    {statuses.map((status) => (
                      <Option key={status} value={status}>
                        {status}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem label='Consultation By'>
                  <Select disabled
                    id="consultationBy"
                    value={selectedConsultationBy}
                    onChange={handleConsultationByChange}
                    placeholder="Select Consultation By"
                  >
                    {staffInfo.map((staff) => (
                      <Option key={staff.name} value={staff.loginId}>
                        {staff.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem label='Confirmation By'>
                  <Select disabled
                    id="confirmationBy"
                    value={selectedConfirmationBy}
                    onChange={handleConfirmationByChange}
                    placeholder="Select Confirmation By"
                  >
                    {staffInfo.map((staff) => (
                      <Option key={staff.name} value={staff.loginId}>
                        {staff.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>




                <FormItem label='Department'>
                  <Select disabled
                    id="department"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Select Department"
                  >
                    {departments.map((department) => (
                      <Option key={department} value={department}>
                        {department}
                      </Option>
                    ))}
                  </Select>
                </FormItem>





              </Form>


              <div>
                {statuses.map((status, index) => (
                  <span key={index} style={{ margin: "5px" }}>
                    <Button type='primary' onClick={() => handleShowAddAppointment(readAppointmentId, status)} >{getStatusValue(status)}</Button>
                  </span>
                ))}
              </div>

              {/* <Button type='primary' onClick={() => handleAppointmentin(readAppointmentId)} >Update</Button> */}
              {/* <div id='PatientAppointmentFormSuccess' style={{ display: "none" }}>
                <Alert type='success' message='Appointment updated successfully' />
              </div> */}
            </div>
          </div>


          {/* Appointment popup Start */}
          <Modal
            open={addingModalVisibility}
            onCancel={closeAddingModal}
            destroyOnClose
            footer={null}
            title={<h3 className='title'>{appointmentDisplayStatus}</h3>}
          >
            <form >
              <div id='RESCHEDULED_ID' style={{ display: activeDiv === 'RESCHEDULED_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <Form.Item label='Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                        onChange={handleDateChange}
                      />
                    </Form.Item>

                    <FormItem label='Remarks'>
                      <Input.TextArea

                        // value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>


                  </Form>
                </Card>

                <div id='appointMentCreateSuccess' style={{ display: "none" }}>
                  <Alert type='success' message='Appointment created successfully' />
                  <br></br>
                </div>

                <div id='appointMentCreateError' style={{ display: "none" }}>
                  <Alert type='error' message='An appointment already created for Patient id Id.' />
                  <br></br>
                </div>

                <div className='d-flex justify-content-between buttons-list settings-actions'>
                  <Button danger onClick={() => closeAddingModal()}>
                    Cancel
                  </Button>

                  <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_RESCHEDULE')} >Update</Button>

                  {/* <Button type='primary'>
                    Submit
                  </Button> */}
                </div>

              </div>

              <div id='ASSIGNED_ID' style={{ display: activeDiv === 'ASSIGNED_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {nonDoctosStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_ASSIGNED')} >Update</Button>


                  </Form>
                </Card>
              </div>


              <div id='TO_ACCOUNTS_ID' style={{ display: activeDiv === 'TO_ACCOUNTS_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {nonDoctosStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_ACCOUNTS')} >Update</Button>


                  </Form>
                </Card>
              </div>

              <div id='TO_INVOICE_ID' style={{ display: activeDiv === 'TO_INVOICE_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {nonDoctosStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Form.Item label='Invoice ID'>
                      <Input onChange={handleInvoiceIdChange} type='text' id='invoiceID' placeholder='Invoice ID' />
                    </Form.Item>


                    <Form.Item label='Invoice Amount'>
                      <Input onChange={handleInvoiceAmountChange} type='text' id='invoiceAmount' placeholder='Invoice Amount' />
                    </Form.Item>


                    <Form.Item label='Invoice Link'>
                      <Input onChange={handleInvoiceLinkChange} type='text' id='invoiceLink' placeholder='Invoice Link' />
                    </Form.Item>

                    <Form.Item label='Invoice Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                         onChange={handleInvoiceDateChange}
                      />
                    </Form.Item>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_INVOICE')} >Update</Button>


                  </Form>
                </Card>
              </div>


              <div id='TO_PHARMACY_ID' style={{ display: activeDiv === 'TO_PHARMACY_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {nonDoctosStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Form.Item label='Payment Status'>
                      <Input onChange={handlePaymentStatusChange} type='text' id='payment_status' placeholder='Payment Status' />
                    </Form.Item>


                    <Form.Item label='Payment Type'>
                      <Input onChange={handlePaymentTypeChange} type='text' id='payment_type' placeholder='Payment Type' />
                    </Form.Item>

                    <Form.Item label='Payment Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                        onChange={handlePaymentDateChange}
                      />
                    </Form.Item>

                    <Form.Item label='Payment mount'>
                      <Input onChange={handlePaymentAmountChange} type='text' id='invoice_link' placeholder='Payment Amount' />
                    </Form.Item>



                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_PHARMACY')} >Update</Button>


                  </Form>
                </Card>
              </div>


              <div id='TO_DOCTOR_ID' style={{ display: activeDiv === 'TO_DOCTOR_ID' ? 'block' : 'none' }}>
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Doctor'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {doctorsStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_DOCTOR')} >Update</Button>


                  </Form>
                </Card>
              </div>

              <div id='IN_CONFIRMATION_ID' style={{ display: activeDiv === 'IN_CONFIRMATION_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Confirmation By'>
                      {/* <Input type='text' id='confirmationBy' value={appointmentInfo.confirmationBy} placeholder='consultationBy' /> */}
                      <Select
                        id="confirmationBy"
                        value={selectedConfirmationBy}
                        onChange={handleConfirmationByChange}
                        placeholder="Select Confirmation By"
                      >
                        {doctorsStaff.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_CONFIRMATION')} >Update</Button>


                  </Form>
                </Card>
              </div>

              <div id='TO_DISPATCH_ID' style={{ display: activeDiv === 'TO_DISPATCH_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_DISPATCH')} >Update</Button>


                  </Form>
                </Card>
              </div>

              <div id='TO_DELIVERY_ID' style={{ display: activeDiv === 'TO_DELIVERY_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Form.Item label='Delivery By'>
                      <Input onChange={handleChangeDeliveryDeliveredBy} type='text' id='deliveryDeliveredBy' placeholder='Delivery By' />
                    </Form.Item>

                    <Form.Item label='Courier Name'>
                      <Input onChange={handleChangeDeliveryCourierName} type='text' id='deliveryCourierName' placeholder='Courier Name' />
                    </Form.Item>

                    <Form.Item label='Courier Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                        onChange={handleChangeDeliveryPickupTime}
                      />
                    </Form.Item>

                    <Form.Item label='Delivery Status'>
                      <Input onChange={handleChangeDeliveryStatus} type='text' id='deliveryStatus' placeholder='Delivery Status' />
                    </Form.Item>

                    <Form.Item label='Delivery Tracking Link'>
                      <Input onChange={handleChangeDeliveryTrackingLink} type='text' id='deliveryTrackingLink' placeholder='Delivery Tracking Link' />
                    </Form.Item>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_DELIVERY')} >Update</Button>


                  </Form>
                </Card>
              </div>




              <div id='CONFIRMED_ID' style={{ display: activeDiv === 'CONFIRMED_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select disabled
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                  </Form>
                </Card>
              </div>



              <div id='AWAITING_PAYMENT_ID' style={{ display: activeDiv === 'AWAITING_PAYMENT_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <Form.Item label='Invoice Number'>
                      <Input disabled type='text' id='invoice_number' placeholder='Invoice Number' />
                    </Form.Item>

                    <Form.Item label='Invoice Amount'>
                      <Input disabled type='text' id='invoice_amount' placeholder='Invoice Amount' />
                    </Form.Item>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        disabled
                        value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                  </Form>
                </Card>
              </div>

              <div id='PAYMENT_RECEIVED_ID' style={{ display: activeDiv === 'PAYMENT_RECEIVED_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <Form.Item label='Payment ID'>
                      <Input disabled type='text' id='payment_id' placeholder='Payment ID' />
                    </Form.Item>

                    <Form.Item label='Payment Type'>
                      <Input disabled type='text' id='payment_type' placeholder='Payment type' />
                    </Form.Item>

                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select disabled
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        disabled
                        value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>


                  </Form>
                </Card>
              </div>

              <div id='IN_PHARMACY_ID' style={{ display: activeDiv === 'IN_PHARMACY_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select disabled
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        disabled
                        value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                  </Form>
                </Card>
              </div>

              <div id='IN_DISPATCH_ID' style={{ display: activeDiv === 'IN_DISPATCH_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Assigned To'>
                      {/* <Input type='text' id='assignedTo' value={appointmentInfo.assignedTo} placeholder='assignedTo' /> */}
                      <Select disabled
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        disabled
                        value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                  </Form>
                </Card>
              </div>

              <div id='CANCELLED_ID' style={{ display: activeDiv === 'CANCELLED_ID' ? 'block' : 'none' }}>
                <Card className='modalClass'>
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Cancell Appointment'>
                      {/* <Input type='text' onChange={handleRemarksChange} id='remarks' value={selectedRemarks} placeholder='remarks' /> */}
                      <Input.TextArea
                        placeholder='Confirm appointment cancelling with reason' rows={4} />
                    </FormItem>
                  </Form>

                  <FormItem label='Remarks'>
                    <Input.TextArea
                      disabled
                      value={selectedRemarks}
                      onChange={handleRemarksChange} id='remarks'
                      placeholder='Remarks' rows={4} />
                  </FormItem>


                </Card>
              </div>

            </form>
          </Modal>
          {/* Appointment popup End */}

          <div className='col-md-6 col-sm-12'>
            <Card>
              {/* <PatientTimeline /> */}
              <Timeline items={timelineItemsDetails} />
            </Card>
          </div>
        </div>

        {/* <Card title='Billings' className='mb-0'>
          <BillingTable billings={billings} />
        </Card> */}
      </>
    )
  );
};

export default AppointmentDetailPage;