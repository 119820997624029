//import React from 'react';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import { useForm } from 'antd/es/form/Form';
import PublicLayout from '../../layout/public/Public';
import { useNavigateHome } from '../../utils/use-navigate-home';
import axios from 'axios';
import Cookies from 'universal-cookie'; 


const SignIn = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameterLogout = searchParams.get('q');

  // const login = () => {
  //   form
  //     .validateFields()
  //     .then(() => navigateHome())
  //     .catch(() => null);
  // };

  const login = async () => {
    try {
      const values = await form.validateFields();
      const url = process.env.React_App_AWS_API+'api/profile/login';
      const headers = {
        'Content-Type': 'application/json',
        'Cookie': 'drmanojpro=s%3AywokcJGWX7x7GRF7WO_pnKSt5p1wT8Vw.iHJ84hPTg5TvHN%2BBtwhb0wJ39DuYxcW5dKHlgaSc%2Bg8; jwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IktWSlFlR1hSRUdKVXpxQ1pjTjBsIiwiaWF0IjoxNjg5ODA2NzI4LCJleHAiOjE2ODk4MDk3Mjh9.sMrLhfCosORCGshbEAIUAjxgyi-xNdeSpjs4Ej3k1n0',
      };
      const data = {
        loginId: values.login,
        password: values.password,
      };

      const response = await axios.post(url, data, { headers });
      //console.log(response);
      console.log("###############");
      var respObject = response.data;

      //console.log(JSON.stringify(respObject));
      
      // Assuming the API returns a JWT token and profile data upon successful login
      const jwtToken = respObject.data.login.jwtToken;
      const profileData = respObject.data.profile;
      //console.log(profileData);
      //console.log(jwtToken);
      // Create a new instance of UniversalCookie
      const cookies = new Cookies();

      // Store the JWT token and profile data in cookies
      cookies.set('jwtToken', jwtToken);
      cookies.set('profileData', JSON.stringify(profileData));
      /*
      var get_profile = cookies.get('profileData');
      console.log("########get profile");
      console.log(JSON.stringify(get_profile));
      */

      // For now, we'll simply navigate to the home page upon successful login
      navigateHome();
    } catch (error) {
      // Handle login error here, e.g., show a message to the user
      console.error('Login failed', error);
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
        //alert("parameterLogout:"+parameterLogout);
        
         // Check if the JWT token is present in cookies
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');

        if(parameterLogout == "1"){
          try {
            const cookies = new Cookies();
            cookies.remove('jwtToken');
            cookies.remove('profileData');
            cookies.remove('*', { path: '/' });
            const response = await axios.post(
              process.env.React_App_AWS_API+'api/profile/logout',
              {},
              {
                headers: {
                 // Cookie: jwtToken,
                  //'Authorization': "jwtToken=" + jwtToken,
                  'Authorization': jwtToken,
                },
              }
            );
      
            // Handle the response if needed (e.g., show a success message)
            console.log('Logout successful!', response.data);
          } catch (error) {
            // Handle errors here (e.g., show an error message)
            console.error('Logout failed!', error);
          }
        }else if (jwtToken) {
          navigateHome();
        }
    }
  //alert("test");

    checkLogin();
  }, []);

  return (
    <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
      <h4 className='mt-0 mb-1'>Login form</h4>

      <p className='text-color-200'>Login to access your Account</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Form.Item name='login' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Login' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Password' type='password' />
        </Form.Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Remember me</span>
        </div>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Login
        </Button>
      </Form>
      <br />
      <p className='mb-1'>
        <a href='#'>Forgot password?</a>
      </p>

      {/* <p>
        Don't have an account? <Link to='../sign-up'>Sign up!</Link>
      </p> */}
    </PublicLayout>
  );
};

export default SignIn;
