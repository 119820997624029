import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select, Timeline, Alert } from 'antd';
import axios from 'axios';
import $ from 'jquery';
import { useFormik } from 'formik';
import { IPageData } from '../../interfaces/page';
import { usePageData } from '../../hooks/usePage';
import { useGetPatient } from '../../hooks/useGetPatient';
import { useGetBillings } from '../../hooks/useGetBillings';
import ImageLoader from '../../layout/components/patients/ImageLoader';
import BillingTable from './components/BillingTable';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

const pageData: IPageData = {
  title: 'Patient profile page',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'Doctors',
      route: 'default-dashboard'
    },
    {
      title: 'Liam Jouns'
    }
  ]
};

const FormItem = Form.Item;
const Option = Select.Option;

const ProfileForm = ({ patient }) => {
  const { values } = useFormik({
    initialValues: { ...patient },
    onSubmit: () => null
  });

  return (
    <Form layout='vertical'>
      <FormItem label='Full name'>
        <Input defaultValue={values.fullName} placeholder='Full name' />
      </FormItem>

      <FormItem label='Id'>
        <Input defaultValue={values.id} placeholder='Id' />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Age'>
            <Input defaultValue={values.age} placeholder='Age' />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Gender'>
            <Select defaultValue={values.gender} placeholder='Gender'>
              <Option value='male'>Male</Option>
              <Option value='female'>Female</Option>
            </Select>
          </FormItem>
        </div>
      </div>

      <FormItem label='Phone'>
        <Input defaultValue={values.phone} placeholder='Phone' />
      </FormItem>

      <FormItem label='Address'>
        <Input.TextArea rows={4} defaultValue={values.address} placeholder='Address' />
      </FormItem>

      <FormItem label='Last visit'>
        <Input defaultValue={values.lastVisit.split('T')[0]} placeholder='Last visit' readOnly />
      </FormItem>

      <FormItem label='Status'>
        <Select defaultValue={values.status} placeholder='Status'>
          <Option value='Approved'>Approved</Option>
          <Option value='Pending'>Pending</Option>
        </Select>
      </FormItem>
    </Form>
  );
};

const PatientTimeline = () => {
  const timelineItems = [
    {
      color: 'red',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>New prescription</h4>
          <span className='text-base text-color-100'>Now</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula
            ut id elit.
          </span>
        </div>
      )
    },
    {
      color: 'blue',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Appointment</h4>
          <span className='text-base text-color-100'>2m ago</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'yellow',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Medication</h4>
          <span className='text-base text-color-100'>2h ago</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'pink',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Operation</h4>
          <span className='text-base text-color-100'>15h ago</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehiculaut id elit.
          </span>
        </div>
      )
    },
    {
      color: 'blue',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>New patient</h4>
          <span className='text-base text-color-100'>Jul 10</span>
          <span className='text-base'>Lorem ipsum dolor sit.</span>
        </div>
      )
    },
    {
      color: 'red',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Examination</h4>
          <span className='text-base text-color-100'>Jul 11</span>
          <span className='text-base'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.
          </span>
        </div>
      )
    },
    {
      color: 'green',
      children: (
        <div className='d-flex flex-column'>
          <h4 className='m-0'>Re-Examination</h4>
          <span className='text-base text-color-100'>Jul 25</span>
          <span className='text-base'>
            Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehiculaut id elit.
          </span>
        </div>
      )
    }
  ];

  return (
    <Timeline items={timelineItems} />
  )
};


const PatientProfilePage = () => {
  const { patient } = useGetPatient('Liam');
  const billings = useGetBillings();
  const [patients, setPatients] = useState<any>({});
  const [readPatientId, setReadPatientId] = useState<any>('');

  const [name, setName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [whatsapp, setWhatsapp] = useState<any>('');
  const [phone, setPhone] = useState<any>('');
  const [dob, setDOB] = useState<any>('');
  const [language, setLanguage] = useState<any>('');
  /*
  const [formData, setFormData] = useState<any>({
    name: '',
    registration_id: '',
    email: '',
    whatsapp: '',
    phone: '',
    dob: '',
    sex: '',
    language: '',
  });
  */

  const [selectedFilterQueryType, setSelectedFilterQueryType] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameterP = searchParams.get('p');
  //
  console.log(parameterP);



  
  const handleChangeName = (e) => {
    const { name, value } = e.target;
    setName(value);
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };


  const handleChangeWhatsapp = (e) => {
    const { name, value } = e.target;
    setWhatsapp(value);
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
    setPhone(value);
  };

  const handleChangeDOB = (e) => {
    const { name, value } = e.target;
    setDOB(value);
  };

  const handleChangeLanguage = (e) => {
    const { name, value } = e.target;
    setLanguage(value);
  };



  /*
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  */

  const handleUpdatePatientin = async (patientId) => {

    $("#PatientFormSuccess").hide();
    const url = process.env.React_App_AWS_API+'api/patients/' + patientId;

    const requestData = {
      dob: dob,
      email: email,
      name: name,
      phone: phone,
      // referrer: $('#referrer').val(),
      //sex: $('#sex').val(),
      whatsapp: whatsapp,
      //registration_id: $('#registration_id').val(),
      language: language,
      //createdOn: 1685518302561,
      //lastUpdatedOn: 1685518302562,
    };

    const requestData2 = {
      dob: '04-10-1952',
      email: '0643ranupapalle52@gmail.com',
      name: 'a.99reddeppareddy2',
      phone: '9848245735',
      referrer: '',
      sex: 'Male',
      whatsapp: '9848245735',
      registration_id: 'A584',
      language: 'English, Telugu, Tamil',
      createdOn: 1685518302561,
      lastUpdatedOn: 1685518302562
    };

    axios.post(url, requestData, {
      headers: {
        'authority': 'clinic.drmanoj.com',
        'accept': 'application/json, text/plain, */*',
        'accept-language': 'en-US,en;q=0.9',
        'content-type': 'application/json;charset=UTF-8',
        'cookie': '_ga=GA1.2.1772364038.1671790294; _gcl_au=1.1.712055127.1680073469',
        'origin': 'https://clinic.drmanoj.com',
        'referer': 'https://clinic.drmanoj.com/users/create?name=Thimmiahgari%20Sanjeevarayappa%20&email=Tsrayappa37%40gmailcom&phone=9247295151&address=',
        'sec-ch-ua': '"Google Chrome";v="113", "Chromium";v="113", "Not-A.Brand";v="24"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'same-origin',
        'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBvaW50bWVudCI6NywiYXR0YWNobWVudCI6NywiYmxvZyI6NywiY29uc2lnbm1lbnQiOjcsImVucXVpcnkiOjcsImlkIjoiYjdiNDFmZjktMTliNy00OTRiLWE2NWItMWJlOWIzZGQ2MmIwIiwibWVkaWEiOjcsIm1lc3NhZ2UiOjcsIm5vdGlmaWNhdGlvbnMiOjcsInBheW1lbnQiOjcsInByZXNjcmlwdGlvbiI6NywicHJvZHVjdCI6NywicmVjb3JkIjo3LCJyZXBvcnRzIjo3LCJyb2xlIjoiYWRtaW4iLCJzZWFyY2giOjMsInVzZXIiOjd9.NCxz9ZVbPZekoH-x1ZJykEkocZqOdeoLTKDFRD9Zdjg',
        'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36',
      }
    })
      .then(response => {
        console.log('Response:', response.data);
        $("#PatientFormSuccess").show();
        setTimeout(function () {
          $("#PatientFormSuccess").hide(); // Hide the element after 3 seconds
        }, 3000);
        // Handle the response data here
      })
      .catch(error => {
        console.error('Error:', error);
        $("#PatientFormSuccess").show();
        setTimeout(function () {
          $("#PatientFormSuccess").hide(); // Hide the element after 3 seconds
        }, 3000);
        // Handle the error here
      });
    /*
    const headers = {
      // Headers... 
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData),
    });

    const resultData = await response.json();
    */


  };
  useEffect(() => {
    $("#PatientFormSuccess").hide();
    const fetchData = async (patientId) => {
      $("#PatientFormSuccess").hide();
      setReadPatientId(parameterP); 
      try {
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };
        const response = await fetch(process.env.React_App_AWS_API+'api/patients/' + patientId,rheader);
        const data = await response.json();
        //const responseData = data.data[0][patientId];
        // var p_data = data.data[0]['0y6h0HL2X9Op28DLCiTy'];
        //var p_data = data.data[0][patientId];
        var p_data = data.data[0];
        setName(p_data.name);
        setEmail(p_data.email);
        setWhatsapp(p_data.whatsapp);
        setLanguage(p_data.language);
        setDOB(p_data.dob);
        setPhone(p_data.phone);

        setPatients(p_data);
      } catch (error) {
        console.error(error);
      }
    };



    fetchData(parameterP);
  }, []);

  usePageData(pageData);

  return (
    patient && (
      <>
        <div className='row mb-4'>
          <div className='col-md-6 col-sm-12'>
            {/* <div className='header mb-3'>
              <h6 className='mt-0 mb-3'>Photo</h6>
              <ImageLoader src={patient.profileImg as string} size={100} />
            </div> */}

            <div className='info stack'>






              <Form layout='vertical'>

                <Form.Item label='Registration ID'>
                  <Input type='text' id='registration_id' value={patients.registrationId} placeholder='Registration ID' />
                </Form.Item>




                <FormItem label='Full name'>
                  <Input onChange={handleChangeName} type='text' id='name' value={name} placeholder='Full name' />

                </FormItem>


                <FormItem label='Email'>
                  <Input onChange={handleChangeEmail} type='text' id='email' value={email} placeholder='Email' />

                </FormItem>

                <FormItem label='Whatsapp'>
                  <Input onChange={handleChangeWhatsapp} type='text' id='whatsapp' value={whatsapp} placeholder='Whatsapp' />
                </FormItem>

                <FormItem label='Phone'>
                  <Input onChange={handleChangePhone} type='text' id='phone' value={phone} placeholder='Phone' />
                </FormItem>

                <FormItem label='Land Phone'>
                  <Input onChange={handleChangePhone} type='text' id='land_phone' value={patients.land_phone} placeholder='Land Phone' />
                </FormItem>

                <FormItem label='Date Of Birth'>
                  <Input onChange={handleChangeDOB} type='text' id='dob' value={dob} placeholder='Date Of Birth' />
                </FormItem>

                <FormItem label='Gender'>
                  <Input type='text' id='sex' value={patients.gender || ''} placeholder='Sex' />
                </FormItem>

                <FormItem label='Language'>
                  <Input onChange={handleChangeLanguage} type='text' id='language' value={language} placeholder='Language' />
                </FormItem>


                <FormItem label='Discount Type'>
                  <Input  type='text' id='discount_type' value={patients.discount_type} placeholder='Discount Type' />
                </FormItem>

                <FormItem label='Discount Note'>
                  <Input  type='text' id='discount_note' value={patients.discount_note} placeholder='Discount Note' />
                </FormItem>

                <FormItem label='Reference'>
                  <Input  type='text' id='reference' value={patients.reference} placeholder='Reference' />
                </FormItem>

              </Form>

              <Button type='primary' onClick={() => handleUpdatePatientin(readPatientId)} >Save Changes</Button>
              <div id='PatientFormSuccess' style={{display:"none"}}>
                <Alert  type='success' message='Patient updated successfully' />
              </div>
            </div>
          </div>

          <div className='col-md-6 col-sm-12'>
            <Card>
              <PatientTimeline />
            </Card>
          </div>
        </div>

        <Card title='Billings' className='mb-0'>
          <BillingTable billings={billings} />
        </Card>
      </>
    )
  );
};

export default PatientProfilePage;
