import { IPatient } from '../interfaces/patient';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/patients/actions';

import { IAppState } from '../interfaces/app-state';

export function usePatients() {
  const dispatch = useDispatch();
  const patients2 = useSelector<IAppState, IPatient[]>((state) => state.patients);
  interface DataItem {
    name: string;
    dob: string;
    phone: string;
    sex: string;
    lastUpdatedOn: number;
    registration_id: string;
    img: string;
  }
  
  interface DataObject {
    data: {
      [key: string]: DataItem;
    };
    status: boolean;
  }
  
  function convertObjectToFormat(dataObject: DataObject) {
    const convertedArray = Object.values(dataObject.data).map((item) => {
      return {
        //img: `/content/user-40-${item.registration_id}.jpg`,
        name: item.name,
        id: item.registration_id,
        age: item.dob,
        address: item.registration_id,
        number: item.phone,
        lastVisit: new Date(item.lastUpdatedOn).toISOString(),
        status: item.registration_id,
        gender: item.sex === 'Male' ? 'Male' : 'Female'
      };
    });
  
    return convertedArray;
  }
  const fetchData = async () => {
    try {
      const response = await fetch(process.env.React_App_AWS_API+'api/patients');
      const dataObject = await response.json();
      //console.log(JSON.stringify(data)); // Log the fetched data to the console
      const convertedData = convertObjectToFormat(dataObject);
      return convertedData;
      // Process the data or update the state with the fetched data
      // For example, you can update the state using a setPatients function
      // setPatients(data);
    } catch (error) {
      console.error(error);
    }
  };

  const patients = [];//fetchData();

  
  console.log(JSON.stringify(patients));

  const editPatient = (patient: IPatient) => {
    return dispatch(actions.editPatient(patient));
  };

  const addPatient = (patient: IPatient) => {
    return dispatch(actions.addPatient(patient));
  };

  const deletePatient = (id: string) => {
    return dispatch(actions.deletePatient(id));
  };

  return { patients, addPatient, editPatient, deletePatient };
}
