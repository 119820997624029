import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Button, Card, Form, Input, Select, Timeline, Alert, Modal, DatePicker, DatePickerProps, TimePicker, message, Space } from 'antd';

import moment from 'moment';

const CaseSheetTabInvoice = ({ parameterP }) => {
  // Use the id prop in your component logic
  // For example, fetch data based on the id from an API
  // ...
  const FormItem = Form.Item;
  const Option = Select.Option;
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState<any>('');
  const [invoiceAmount, setInvoiceAmount] = useState<any>('');
  const [invoiceId, setInvoiceId] = useState<any>('');
  const [staffInfo, setStaffInfo] = useState<any>([]);
  const [paymentDate, setPaymentDate] = useState<any>('');
  const [paymentStatus, setPaymentStatus] = useState<any>('');
  const [paymentType, setPaymentType] = useState<any>('');
  const [paymentAmount, setPaymentAmount] = useState<any>('');
  const [selectedRemarks, setSelectedRemarks] = useState('');
  const [selectedAssignedTo, setSelectedAssignedTo] = useState('');
  const [readAppointmentId, setReadAppointmentId] = useState<any>('');
  const [addingModalVisibility, setAddingModalVisibility] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState<any>('');
  const [invoiceDateInput, setInvoiceDateInput] = useState<any>('');

  const formatDate = (isoDate) => {
    const dateObj = moment(isoDate);
    return dateObj.format('MMMM Do, YYYY h:mm A');
  };

  const handlePaymentDateChange = (value) => {
    //const { name, value } = e.target;
    setPaymentDate(value);
  };
  const handleInvoiceLinkChange = (e) => {
    const { name, value } = e.target;
    setInvoiceLink(value);
  };

  const handlePaymentStatusChange = (e) => {
    const { name, value } = e.target;
    setPaymentStatus(value);
  };

  const handlePaymentTypeChange = (e) => {
    const { name, value } = e.target;
    setPaymentType(value);
  };

  const handlePaymentAmountChange = (e) => {
    const { name, value } = e.target;
    setPaymentAmount(value);
  };

  const handleAssignedToChange = (value) => {
    setSelectedAssignedTo(value);
  };

  const handleInvoiceAmountChange = (e) => {
    const { name, value } = e.target;
    setInvoiceAmount(value);
  };

  const handleInvoiceIdChange = (e) => {
    const { name, value } = e.target;
    setInvoiceId(value);
  };

  const handleRemarksChange = (e) => {
    const { name, value } = e.target;
    setSelectedRemarks(value);
  };

  const  successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  const handleInvoiceDateChange = (value) => {
    const formattedDate = value ? value.toISOString() : '';
    setInvoiceDateInput(formattedDate);
  };

  const handleAppointmentinPart = async (appointmentId, customStatusOperation) => {

    try {
      const url = process.env.React_App_AWS_API+'api/appointments/' + appointmentId;

      const headers = {
        'Content-Type': 'application/json',
      };

      switch (customStatusOperation) {

        case 'TO_RECEIPT':
          var data_to_pharmacy = {
            "staffId": "165724",
            "statusCode": "TO_RECEIPT",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "paymentStatus": paymentStatus,
              "paymentType": paymentType,
              "paymentDate": paymentDate,
              "paymentAmount": paymentAmount,
              "invoiceId": invoiceId,
              "remarks": "Sent to Pharmacy"
            }
          };
          updateAppointment(appointmentId, data_to_pharmacy);

          break;

        case 'TO_INVOICE':         
          var data_to_invoice = {
            "staffId": "165724",
            "statusCode": "TO_INVOICE",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,
              "invoiceDate": invoiceDateInput,
              "invoiceLink": invoiceLink,
              "invoiceAmount": invoiceAmount,
              "invoiceId": invoiceId,
              "remarks": "Sent to Invoice"
            }
          };
        
         updateAppointment(appointmentId, data_to_invoice);
          
          break;
     
        case 'TO_ACCOUNTS':
          var data_to_accounts = {
            "staffId": "165724",
            "statusCode": "TO_ACCOUNTS",
            "remarks": selectedRemarks,
            "appointment": {
              "assignedTo": selectedAssignedTo,              
              "remarks": "Sent to accounts"
            }
          };
          updateAppointment(appointmentId, data_to_accounts);
          
          break;

      }
     
    } catch (error) {
      console.error(error);
    }

  };

  const updateAppointment = async (appointmentId, data) => {
    //setLoading(true);
    const url = process.env.React_App_AWS_API +'api/appointments/' + appointmentId;
    console.log("test 406");
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        },
        body: JSON.stringify(data)
      });

      if (response) {
        const responseData = await response.json();
        console.log(responseData);
 
        //setLoading(false);
        successAlert(responseData.message);
        
         
        
         
        
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setReadAppointmentId(parameterP);
    const fetchStaffData = async () => {
      try {
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        var rheader = {
          method: 'GET',
          headers: {
            //'Authorization': "jwtToken=" + jwtToken,
            'Authorization': jwtToken,
          }
        };
        //alert(jwtToken)
        const response = await fetch(process.env.React_App_AWS_API+'api/staff',rheader);
        if (response.ok) {
          const data = await response.json();
          console.log('API response:', data); // Check the response data

          // Assuming the response contains the 'data' property with an array of departments
          setStaffInfo(data.data);
        } else {
          console.error('Error fetching departments:', response.status);
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };
    fetchStaffData();

    const fetchInvoicenData = async (appointmentId) => {
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        }
      };
      fetch(process.env.React_App_AWS_API+'api/appointments/' + parameterP + '/invoice',rheader)
        .then(response => response.json())
        .then(data => {
          // Process the received data here
          console.log("Received Data:", data);
          if (data.status === true) {
            setInvoiceData(data.data);
          } else {
            setInvoiceData(null);
          }


          console.log(data);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error(error);
        });

    };

    fetchInvoicenData(parameterP);
  }, []);
  
  return (
    <div>
      {invoiceData ? (
        <>
            {contextHolder}
          {/* Payment Section */}
          <Card title="Payment" style={{ marginBottom: 16 }}>
            <p>Payment ID: {invoiceData.payment.paymentId}</p>
            <p>Payment Status: {invoiceData.payment.paymentStatus}</p>
            <p>Payment Amount: {invoiceData.payment.paymentAmount}</p>
            <p>Payment Date: {formatDate(invoiceData.payment.paymentDate)}</p>
            <p>Payment Type: {invoiceData.payment.paymentType}</p>


            
                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Form.Item label='Payment Status'>
                      <Input onChange={handlePaymentStatusChange} type='text' id='payment_status' placeholder='Payment Status' />
                    </Form.Item>


                    <Form.Item label='Payment Type'>
                      <Input onChange={handlePaymentTypeChange} type='text' id='payment_type' placeholder='Payment Type' />
                    </Form.Item>

                    <Form.Item label='Payment Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                        onChange={handlePaymentDateChange}
                      />
                    </Form.Item>

                    <Form.Item label='Payment mount'>
                      <Input onChange={handlePaymentAmountChange} type='text' id='invoice_link' placeholder='Payment Amount' />
                    </Form.Item>



                    <FormItem label='Remarks'>
                      <Input.TextArea

                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_RECEIPT')} >Update</Button>


                  </Form>
                </Card>
            
          </Card>

          {/* Invoice Section */}
          <Card title="Invoice">
            <p>Invoice ID: {invoiceData.invoice.invoiceId}</p>
            <p>Invoice Amount: {invoiceData.invoice.invoiceAmount}</p>
            <p>Invoice Date: {formatDate(invoiceData.invoice.invoiceDate)}</p>
            <p>Invoice Link: {invoiceData.invoice.invoiceLink}</p>

                <Card className='modalClass' >
                  <Form labelAlign='left' layout='horizontal' >
                    <FormItem label='Select Staff'>
                      <Select
                        id="assignedTo"
                        value={selectedAssignedTo}
                        onChange={handleAssignedToChange}
                        placeholder="Select Assigned To"
                      >
                        {staffInfo.map((staff) => (
                          <Option key={staff.name} value={staff.loginId}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <Form.Item label='Invoice ID'>
                      <Input onChange={handleInvoiceIdChange} type='text' id='invoiceID' placeholder='Invoice ID' />
                    </Form.Item>


                    <Form.Item label='Invoice Amount'>
                      <Input onChange={handleInvoiceAmountChange} type='text' id='invoiceAmount' placeholder='Invoice Amount' />
                    </Form.Item>


                    <Form.Item label='Invoice Link'>
                      <Input onChange={handleInvoiceLinkChange} type='text' id='invoiceLink' placeholder='Invoice Link' />
                    </Form.Item>

                    <Form.Item label='Invoice Date' className='form-group'>
                      <DatePicker
                        placeholder='Date'
                         onChange={handleInvoiceDateChange}
                      />
                    </Form.Item>

                    <FormItem label='Remarks'>
                      <Input.TextArea
                        //value={selectedRemarks}
                        onChange={handleRemarksChange} id='remarks'
                        placeholder='Remarks' rows={4} />
                    </FormItem>

                    <Button type='primary' loading={loading} onClick={() => handleAppointmentinPart(readAppointmentId, 'TO_INVOICE')} >Update</Button>


                  </Form>
                </Card>


          </Card>
        </>
      ) : (
        <p>Loading Invoice data...</p>
      )}
    </div>
  );
};

export default CaseSheetTabInvoice;
