import React, { useEffect, useState } from 'react'; 
import $ from 'jquery';
import { usePageData } from '../../../hooks/usePage';
import { usePatients } from '../../../hooks/usePatients';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import AppointmentForm from '../appointments/AppointmentForm';
import { IPatient } from '../../../interfaces/patient';
import axios from 'axios';
import Cookies from 'universal-cookie';
import PatientsTable from './PatientsTable';
import { Avatar, Table, Button, Card, Modal, Tag, Form, Input, Select, Alert, DatePicker, DatePickerProps, TimePicker,  message, Space } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons/lib';


import { IPageData } from '../../../interfaces/page';
const TextArea = Input.TextArea;

const pageData: IPageData = {
  title: 'Patients',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'Patients'
    }
  ]
};

const PatientsPage = () => {
  const { patients: patientsPromise, editPatient, deletePatient } = usePatients();
  const [patients, setPatients] = useState<any>([]);
  const [selectedFilterQueryType, setSelectedFilterQueryType] = useState('');
  const [addingModalVisibility, setAddingModalVisibility] = useState(false);

  const [appPatientPrimaryKey, setAppPatientPrimaryKey] = useState<any>('');
  const [appPatientName, setAppPatientName] = useState<any>('');
  const [appPatientID, setAppPatientID] = useState<any>('');
  const [prefferedDoctorGender, setPrefferedDoctorGender] = useState<any>('');
  const [dispatchType, setDispatchType] = useState<any>('');
  const [billingAddress, setBillingAddress] = useState<any>('');
  const [shippingAddress, setShippingAddress] = useState<any>('');
  const [appointmentDate, setAppointmentDate] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  

  usePageData(pageData);
  const navigate = useNavigate();
  interface DataItem {
    name: string;
    dob: string;
    phone: string;
    sex: string;
    lastUpdatedOn: number;
    registration_id: string;
    img: string;
    email: any;
    entityId:any;
  }

  interface DataObject {
    data: {
      [key: string]: DataItem;
    };
    status: boolean;
  }
/*
  function convertObjectToFormat(dataObject: DataObject) {
    try{
      var convertedArray = Object.entries(dataObject.data).map(([key, item], index) => {
        //console.log(JSON.stringify(item));
        return {
        
          //PatientPrimaryKey: key, // Maintain key for all objects except the last one
          PatientPrimaryKey: item.entityId, // Maintain key for all objects except the last one
          name: item.name || '',
          registration_id: item.registration_id || '',
          email: item.email || '',
          age: item.dob || '',
          address: item.registration_id || '',
          number: item.phone || '',
          lastVisit: new Date(item.lastUpdatedOn).toISOString() || '',
          status: item.registration_id || '',
          gender: item.sex === 'Male' ? 'Male' : 'Female' || ''
        };
      });
    }catch(ex){
      console.log("exception");
      console.log(ex);
    }
    

    return convertedArray;
  }
  */

  function convertObjectToFormat(dataObject) {
    try {
      if (!dataObject || !dataObject.data || !Array.isArray(dataObject.data)) {
        throw new Error('Invalid dataObject or data property is not an array');
      }
  
      var convertedArray = dataObject.data.map((item) => {
        return {
          PatientPrimaryKey: item.entityId,
          name: item.name || '',
          registration_id: item.registrationId || '',
          email: item.email || '',
          age: item.dob || '',
          address: item.registrationId || '',
          number: item.phone || '',
          //lastVisit: new Date(item.lastUpdatedOn).toISOString() || '',
          status: item.registrationId || '',
          gender: item.sex === 'Male' ? 'Male' : 'Female' || ''
        };
      });
      console.log("116");
      console.log(convertedArray);
      return convertedArray;
    } catch (ex) {
      console.log('Exception:', ex);
      return [];
    }
  }

  //const handleShowInfo = () => navigate('/vertical/patient-profile');
  //const handleDeletePatient = (id) => onDeletePatient(id);

  const handleDispatchType = (value) => {
    setDispatchType(value);
  };
  const handleBillingAddress = (e) => {
    const { name, value } = e.target;
    setBillingAddress(value);
  };

  const handleShippingAddress = (e) => {
    const { name, value } = e.target;
    setShippingAddress(value);
  };

  const handlePrefferedDoctorGenderChange = (value) => {
    setPrefferedDoctorGender(value);
  };


  const handleDateChange = (value) => {
    setAppointmentDate(value);
  };
  


  const handleShowInfo = (patient: IPatient) => {
    console.log(patient);

    navigate('/vertical/patient-profile?p=' + patient.PatientPrimaryKey)
    //setPatient(patient);
    //setVisibility(true);
  };

  const successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  const handleCreateAppointmentin = async () => {
    setLoading(true);
    const requestData = {
      patientId: appPatientPrimaryKey,
      staffId: '889100',
      appointment: {
        status: "CREATED",
        appointmentDate: appointmentDate,
        prefferedDoctorGender: prefferedDoctorGender,
        prefferedTimeSlot: 'PrefferedTimeSlot',
        assignedTo: '',
        consultationBy: '',
        confirmedBy: '',
        patientID: appPatientID,
        dispatchType: dispatchType,
        billingAddress: billingAddress,
        consignmentID: '',
        shippingAddress: shippingAddress,
        remarks: '',
        remarkedBy: '',
        urgent: '',
      },
    };
    const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
    var rheader = {
      //'Authorization': "jwtToken=" + jwtToken,
      'Authorization': jwtToken,
    };

    axios.put(process.env.React_App_AWS_API+'api/appointments', requestData,  { headers: rheader } )
      .then(response => {
        // Handle the response data
        console.log(response.data);
        setLoading(false);
        //alert("test");
        successAlert(response.data.message);
        //$("#appointMentCreateSuccess").show();
        closeAddingModal();
        /*
        messageApi.open({
          type: 'success',
          content: response.data.message//'This is a success message',
        });
        */

       
        /*
        setTimeout(function () {
          $("#appointMentCreateSuccess").hide(); // Hide the element after 3 seconds
        }, 3000);
        */
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
        setLoading(false);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message;
          // Display the error message in the UI using the notification library or any other method you prefer
          messageApi.open({
            type: 'error',
            content: errorMessage,
          });
        } else {
          // If the response data is not available, show a generic error message
          messageApi.open({
            type: 'error',
            content: 'An error occurred while processing your request.',
          });
        }
    
        //$("#appointMentCreateError").show();
        setTimeout(function () {
          $("#appointMentCreateError").hide(); // Hide the element after 3 seconds
        }, 3000);

      });
  }

  const handleShowAddAppointment = (patient: IPatient) => {
    console.log(patient);
    setAppPatientPrimaryKey(patient.PatientPrimaryKey)
    setAppPatientName(patient.name);
    setAppPatientID(patient.registration_id)
    setAddingModalVisibility(true);
  };

  const openAddingModal = () => setAddingModalVisibility(true);
  const closeAddingModal = () => setAddingModalVisibility(false);


  const handleEditPatient = (patient: IPatient) => {
    //setPatient(patient);
    //setVisibility(true);
  };
  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={handleShowInfo.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>

      <Button onClick={handleShowAddAppointment.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-stethoscope-alt' />
      </Button>

    </div>
  );

  const columns: ColumnProps<IPatient>[] = [

    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'registration_id',
      dataIndex: 'registration_id',
      title: 'ID',
      sorter: (a, b) => (a.registration_id > b.registration_id ? 1 : -1),
      render: (registration_id) => <span style={{ minWidth: 200, display: 'block' }}>{registration_id}</span>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      render: (email) => <span style={{ minWidth: 200, display: 'block' }}>{email}</span>
    },
    {
      key: 'number',
      dataIndex: 'number',
      title: 'Phone Number',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {phone}
        </span>
      )
    },
    // {
    //   key: 'visit',
    //   dataIndex: 'lastVisit',
    //   title: 'Last visit',
    //   render: (visit) => (
    //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
    //       {visit.split('T')[0]}
    //     </span>
    //   )
    // },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }


  ];

  const pagination = patients.length <= 10 ? false : {};
  const { Option } = Select;


  const handleChangefilterQueryBy = (value, option) => {
    console.log('Selected value:', value);
    console.log('Selected index:', option.key);
    setSelectedFilterQueryType(value);
  };
  const fetchPatientsByFilter = async () => {
    var filter = selectedFilterQueryType;
    var query = $("#filterQueryInput").val();

    var filterQueryInputEmail = $("#filterQueryInputEmail").val();
    var filterQueryInputPhone = $("#filterQueryInputPhone").val();
    var filterQueryInputPatientID = $("#filterQueryInputPatientID").val();

    if(filterQueryInputPatientID != ""){
      query = filterQueryInputPatientID;
      filter = "ID";
    }else if(filterQueryInputPhone != ""){
      query = filterQueryInputPhone;
      filter = "Phone";
    }else if(filterQueryInputEmail != ""){
      query = filterQueryInputEmail;
      filter = "Email";
    }

    const apiUrl = process.env.React_App_AWS_API+`api/patients?filter=${filter}&query=${query}`;
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    var rheader = {
      method: 'GET',
      headers: {
        //'Authorization': "jwtToken=" + jwtToken,
        'Authorization': jwtToken,
      }
    };
    try {
      const resolvedPatients = await patientsPromise;
      const response = await fetch(apiUrl,rheader);
      const dataObject = await response.json();
      //const convertedData = dataObject.data;
      console.log("264");
      const convertedData = convertObjectToFormat(dataObject);
      console.log("convertedData");
      console.log(JSON.stringify(convertedData));
      setPatients(convertedData);
    } catch (error) {
      // Handle the error if needed
    }
  };

  const handleFilterPatient = () => {
    //alert("test");
    /*
    messageApi.open({
      type: 'error',
      content: 'This is an error message',
    });
    messageApi.open({
      type: 'success',
      content: 'This is a prompt message with custom className and style',
      className: 'custom-class',
      style: {
        marginTop: '50vh',
        opacity:'9999999',
        zIndex:'999999'
      },
    });
    */


    fetchPatientsByFilter();
    
  };
  const fetchPatients = async () => {
    try {
      const resolvedPatients = await patientsPromise;
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      var rheader = {
        method: 'GET',
        headers: {
          'Authorization': "jwtToken=" + jwtToken,
        }
      };
      const response = await fetch(process.env.React_App_AWS_API+'api/patients',rheader);
      const dataObject = await response.json();
      const convertedData = convertObjectToFormat(dataObject);
      setPatients(convertedData);
    } catch (error) {
      // Handle the error if needed
    }
  };
  /*
  useEffect(() => {     
    console.log("pt loading...")
   // fetchPatients();
  }, [patientsPromise]);
  */

  return (
    <>
      {contextHolder}

      {/* Appointment popup Start */}
      <Modal
        open={addingModalVisibility}
        onCancel={closeAddingModal}
        destroyOnClose
        footer={null}
        title={<h3 className='title'>Add appointment</h3>}
      >
        <form >
          <Card >
            <Form labelAlign='left' layout='horizontal' >
              <Form.Item label='Patient Name'>
                <Input placeholder='Patient Name' value={appPatientName} disabled />
              </Form.Item>

              <Form.Item label='Patient Reg ID'>
                <Input placeholder='Patient Name' value={appPatientID} disabled />
              </Form.Item>

              <Form.Item label='Date' className='form-group'>
                <DatePicker                  
                  placeholder='Date'                  
                  onChange={handleDateChange}
                />
              </Form.Item>

              <Form.Item label='Preferred Doctor Gender'>
                <Select
                  onChange={handlePrefferedDoctorGenderChange}
                  placeholder='Preferred Doctor Gender'
                  optionFilterProp='children'
                  className='mb-2 mb-md-0'
                >
                  <Option value='Male'>Male</Option>
                  <Option value='Female'>Female</Option>
                </Select>
              </Form.Item>

              <Form.Item label='Dispatch Option'>
                <Select
                  onChange={handleDispatchType}
                  defaultValue='Pickup'
                  placeholder='Select a Dispatch'
                  optionFilterProp='children'
                  className='mb-2 mb-md-0'
                >
                  <Option value='Pickup'>Pickup</Option>
                  <Option value='Courier'>Courier</Option>
                </Select>
              </Form.Item>

              <Form.Item label='Billing Address'>
                <TextArea
                  onChange={handleBillingAddress}
                  defaultValue='Billing Address' rows={3} />
              </Form.Item>

              <Form.Item label='Shipping Address'>
                <TextArea
                  onChange={handleShippingAddress}
                  defaultValue='Shipping Address' rows={3} />
              </Form.Item>
            </Form>
          </Card>

          <div id='appointMentCreateSuccess' style={{ display: "none" }}>
            <Alert type='success' message='Appointment created successfully' />
            <br></br>
          </div>

          <div id='appointMentCreateError' style={{ display: "none" }}>
            <Alert type='error' message='An appointment already created for Patient id Id.' />
            <br></br>
          </div>

          <div className='d-flex justify-content-between buttons-list settings-actions'>
            <Button danger onClick={() => closeAddingModal()}>
              Cancel
            </Button>

            <Button loading={loading}  onClick={() => handleCreateAppointmentin()} type='primary'>
              Submit
            </Button>
          </div>
        </form>
      </Modal>
      {/* Appointment popup End */}

      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Card title='' className='mb-0'>
            <Form layout='vertical'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  {/* <Select
                    id="filterQueryBy"
                    defaultValue=''
                    placeholder='Filter By'
                    className='mb-2 mb-md-0'
                    onChange={handleChangefilterQueryBy}
                  >
                    <Option value=''>Filter By</Option>
                    <Option value='Phone'>Phone</Option>
                    <Option value='Email'>Email</Option>
                    <Option value='RegistrationID'>Reg ID</Option>
                  </Select> */}

                  <Form.Item label='Search By Email'>
                    <Input
                      id="filterQueryInputEmail"
                      placeholder='Email Address'
                      className='mb-md-0 mb-4'
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />
                  </Form.Item>

                </div>
                <div className='col-md-3 col-sm-12'>
                  <Form.Item label='Search By Phone'>
                    <Input
                      id="filterQueryInputPhone"
                      placeholder='Phone Number'
                      className='mb-md-0 mb-4'
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />
                  </Form.Item>
                </div>
                <div className='col-md-3 col-sm-12'>
                  <Form.Item label='Search By Patient ID '>
                    <Input
                      id="filterQueryInputPatientID"
                      placeholder='Patient ID'
                      className='mb-md-0 mb-4'
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />
                  </Form.Item>
                </div>

                <div className='col-md-1 col-sm-12'>
                <Form.Item label='&nbsp;'>
                  <Button block type='primary' onClick={handleFilterPatient} htmlType='submit'>
                    Search
                  </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12 custom-mb-10'>
        </div>
      </div>

      <Table
        pagination={pagination}
        className='accent-header'
        rowKey={(record) => record.PatientPrimaryKey}
        dataSource={patients}
        columns={columns}
      />
    </>
  );
};

export default PatientsPage;