import React from 'react';
import { useLocation } from 'react-router-dom'; 
import { Avatar, Card, Skeleton, Switch,  message, Space } from 'antd';
//import CaseSheetTabOthers from './CaseSheetTabOthers';
import CaseSheetTabInvoice from './CaseSheetTabInvoice';
import CaseSheetTabPrescription from './CaseSheetTabPrescription';
import AppointmentDetailPage from './AppointmentDetailPage';
import CaseSheetTabCaseSheet from './CaseSheetTabCaseSheet';
// other imports...

const tabListNoTitle = [
  {
    key: 'appointmentDetail',
    tab: 'Appointment'
  },
  {
    key: 'caseSheetDetail',
    tab: 'Case Sheet'
  },
  {
    key: 'prescriptionDetail',
    tab: 'Prescription'
  },
  {
    key: 'InvoiceDetail',
    tab: 'Invoice'
  },
  // other tabs...
];




const CaseSheetDetailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameterP = searchParams.get('p');
  const [noTitleKey, setNoTitleKey] = React.useState('appointmentDetail');
  const [messageApi, contextHolder] = message.useMessage();

  const contentListNoTitle = {
    appointmentDetail: <AppointmentDetailPage parameterP={parameterP} />, 
    caseSheetDetail: <CaseSheetTabCaseSheet parameterP={parameterP} />, 
    prescriptionDetail: <CaseSheetTabPrescription parameterP={parameterP} />, 
    InvoiceDetail: <CaseSheetTabInvoice parameterP={parameterP} />, 
  };
  const onTabChange = key => {
    setNoTitleKey(key);
  };

  return (
    
    <Card
      style={{ width: '100%' }}
      tabList={tabListNoTitle}
      activeTabKey={noTitleKey}
      onTabChange={onTabChange} 
    >
      
      {contentListNoTitle[noTitleKey]}
    </Card>
  );
};

export default CaseSheetDetailPage;
