import axios from "axios";
import Cookies from 'universal-cookie';
//const accessTokenTo = sessionStorage.getItem("accToken");
const accessTokenTo = "test";
const cookies = new Cookies();
const jwtToken = cookies.get('jwtToken');
//alert("jwttoken:"+jwtToken)
export default axios.create({
  baseURL: process.env.React_App_AWS_API,
  headers: {
    "Content-type": "application/json",
    //'Cookie': "jwtToken=" + jwtToken,
    //'Authorization': "Bearer " + accessTokenTo
    //'Authorization': "jwtToken=" + jwtToken,
    'Authorization':  jwtToken,
  }
});