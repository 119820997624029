//import React from 'react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { Card } from 'antd';
// import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
import CustomAppointmentsPage from '../appointments/CustomAppointmentsPage';
import DashboardWidgetPage from './DashboardWidgets';
import hospitalOptions from './charts/hospital-options';
import { incomeInWeek, incomeInMonth } from './charts/income-options';
import {
  patientsGenderOptions,
  departmentsOptions,
  patientsAgeOptions
} from './charts/patients-options';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';
import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import Cookies from 'universal-cookie';

const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'default-dashboard'
    },
    {
      title: 'Default'
    }
  ]
};

const tabListNoTitle = [
  {
    key: 'appointmentTab',
    tab: 'Appointment'
  },
  {
    key: 'widgetTab',
    tab: 'Summary Widget'
  },
 
  // other tabs...
];
const DashboardPage = () => {
  const navigate = useNavigate();
  const [appointments] = useFetchPageData<IAppointment[]>('./data/last-appointments.json', []);
  const [noTitleKey, setNoTitleKey] = React.useState('appointmentTab');
  usePageData(pageData);


  const contentListNoTitle = {
    appointmentTab: <CustomAppointmentsPage  />, 
    widgetTab: <DashboardWidgetPage/>, 
   
  };
  const onTabChange = key => {
    setNoTitleKey(key);
  };


  useEffect(() => {
    const checkLogin = async () => {
         // Check if the JWT token is present in cookies
        const cookies = new Cookies();
        const jwtToken = cookies.get('jwtToken');
        //alert(jwtToken)
        if (!jwtToken) {
          // Redirect to the login page if JWT token is not found
          //history.push('/#/public/sign-in');
          //alert("fail")
          window.location.href="#/public/sign-in";
          //const route = '/#/public/sign-in';
          //navigate(route);
        }
    }
  //alert("test");

    checkLogin();
  }, []);

  return (
    <>

      <Card
      style={{ width: '100%' }}
      tabList={tabListNoTitle}
      activeTabKey={noTitleKey}
      onTabChange={onTabChange} 
    >
      
      {contentListNoTitle[noTitleKey]}
    </Card>

      
     






     

      


    </>
  );
};

export default DashboardPage;
