import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Avatar, Table, Button, Card, Modal, Tag, Form, Input, Select, Alert, DatePicker, DatePickerProps, TimePicker } from 'antd';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import EditAppointment from './EditAppointment';
import AddAppointment from './AddAppointment';
import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
import { useNavigate } from 'react-router-dom';
import PageAction from '../../../layout/components/page-action/PageAction';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';
import { ColumnProps } from 'antd/es/table';
import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons/lib';
import services from '../../../services/services';
import Cookies from 'universal-cookie';

const pageData: IPageData = {
  title: '',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'Appointments'
    }
  ]
};

const CustomAppointmentsPage = () => {
  usePageData(pageData);
  const [appointments, setAppointments] = useFetchPageData<IAppointment[]>(
    './data/appointments.json',
    []
  );

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [addingModalVisibility, setAddingModalVisibility] = useState(false);
  /////Head start
  const [customAppointments, setCustomAppointments] = useState<any>([]);
  const [selectedFilterQueryType, setSelectedFilterQueryType] = useState('date');
  interface ICustomAppointment {
    phone?: any;
    dob: any;
    email: any;
    name: any;
    referrer: any;
    sex: any;
    whatsapp: any;
    registration_id: any;
    language: any;
    familyMember: any;
    parentId: any;
    relationship: any;
    gender: any;
    PatientPrimaryKey: any;
    status: any,
    patientName: any

  }

  // Use the IPatient interface within the component
  const patient: ICustomAppointment = {
    phone: '1234567890',
    dob: '1990-01-01',
    email: 'example@example.com',
    name: 'John Doe',
    referrer: 'Referrer',
    sex: 'Male',
    whatsapp: '1234567890',
    registration_id: '123',
    language: 'English',
    familyMember: 'Family Member',
    parentId: '456',
    relationship: 'Relationship',
    gender: 'Male',
    PatientPrimaryKey: '789',
    status: 'assigned',
    patientName: '',

  };
  ////Head end

  const handleDelete = (appointment: IAppointment) => {
    const newAppointments = appointments.filter((el) => el !== appointment);
    setAppointments(newAppointments);
  };

  const handleEdit = (appointment: IAppointment) => {
    const editedAppointments = appointments.map((el) =>
      el !== selectedAppointment ? el : appointment
    );
    setAppointments(editedAppointments);
    setSelectedAppointment(null);
  };

  const openAddingModal = () => setAddingModalVisibility(true);

  const addAppointment = (appointment: IAppointment) => {
    setAppointments([appointment, ...appointments]);
    setAddingModalVisibility(false);
  };

  const closeAddingModal = () => setAddingModalVisibility(false);

  const openEditModal = (appointment: IAppointment) => setSelectedAppointment(appointment);

  const closeModal = () => {
    setSelectedAppointment(null);
  };

  const appointmentsActions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button onClick={openEditModal.bind({}, appointment)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={handleDelete.bind({}, appointment)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  ///////Start
  const navigate = useNavigate();
  const handleChangefilterQueryBy = (value, option) => {
    console.log('Selected value:', value);
    console.log('Selected index:', option.key);
    setSelectedFilterQueryType(value);
  };
  const formatDate = (indate) => {
    if (indate == "") {
      return "";
    }
    const date = moment(indate, 'DDMMYYYY');
    const beautifulDate = date.format('MMMM DD, YYYY');
    return beautifulDate;
  };

  const handleShowInfo = (patient: ICustomAppointment) => {
    console.log(patient);

    //navigate('/vertical/appointment-detail?p=' + patient.PatientPrimaryKey)
    navigate('/vertical/case-sheet-detail?p=' + patient.PatientPrimaryKey)
    //setPatient(patient);
    //setVisibility(true);
  };

  function convertObjectToFormat(dataObject) {
    try {
      if (!dataObject || !dataObject.data || !Array.isArray(dataObject.data)) {
        throw new Error('Invalid dataObject or data property is not an array');
      }

      var convertedArray = dataObject.data.map((item) => {
        return {
          PatientPrimaryKey: item.entityId,
          remarks: item.remarks,
          staffId: item.staffId,
          patientID: item.patientID,
          department: item.department,
          status: item.status,
          assignedTo: item.assignedTo,
          appointmentDate: formatDate(item.appointmentDate),
          consultationBy: item.consultationBy,
          patientName: item.patientName,
          phone: item.phone



          //name: item.name || '',
          //registration_id: item.registrationId || '',
          //email: item.email || '',
          //age: item.dob || '',
          //address: item.registrationId || '',
          //number: item.phone || '',
          //lastVisit: new Date(item.lastUpdatedOn).toISOString() || '',
          //gender: item.sex === 'Male' ? 'Male' : 'Female' || ''
        };
      });
      console.log("116");
      console.log(convertedArray);
      return convertedArray;
    } catch (ex) {
      console.log('Exception:', ex);
      return [];
    }
  }

  // const handleFilterCustomAppointment = () => {
  //   fetchCustomAppointmentByFilter(); 
  // };

  const handleFilterCustomAppointment = (date, dateString) => {
    setSelectedDate(date);
    const formattedDate = date.format('DDMMYYYY'); // Format the date as "DDMMYYYY"
    // Pass the formatted date to your desired function here
    console.log('Formatted date (DDMMYYYY):', formattedDate);
    fetchCustomAppointmentByFilter();
    // Call your function and pass 'formattedDate' as an argument
    // yourFunction(formattedDate);
  };

  const fetchCustomAppointmentByFilter = async () => {
    var filter = 'date';//selectedFilterQueryType;
    var query = selectedDate;//$("#filterQueryInput").val();
    const formattedDate = query.format('DDMMYYYY');
    //alert(formattedDate);
    var apiUrl = process.env.React_App_AWS_API + `api/appointments?${filter}=${formattedDate}`;
    //alert("199")

    ///////////////////


    console.log("test 406");
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    //alert(jwtToken)
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
        },
        // body: JSON.stringify(data)
      });

      if (response) {
        console.log(JSON.stringify(response));
        // const response = await fetch(apiUrl);
        const dataObject = await response.json();//await response.json();
        //const convertedData = dataObject.data;
        console.log("264");
        const convertedData = convertObjectToFormat(dataObject);
        console.log("convertedData");
        console.log(JSON.stringify(convertedData));
        setCustomAppointments(convertedData);

      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }

    /////////////////
    /*
    services.getAppointments(query).then((response) => {
      if (response) {
         console.log(JSON.stringify(response));
        // const response = await fetch(apiUrl);
         const dataObject = response.data;//await response.json();
         //const convertedData = dataObject.data;
         console.log("264");
         const convertedData = convertObjectToFormat(dataObject);
         console.log("convertedData");
         console.log(JSON.stringify(convertedData));
         setCustomAppointments(convertedData);

      }
   });
   */

    /* 
   try {
     //const resolvedPatients = await patientsPromise;
     const response = await fetch(apiUrl);
     const dataObject = await response.json();
     //const convertedData = dataObject.data;
     console.log("264");
     const convertedData = convertObjectToFormat(dataObject);
     console.log("convertedData");
     console.log(JSON.stringify(convertedData));
     setCustomAppointments(convertedData);
   } catch (error) {
     // Handle the error if needed
   }
   */
  };

  useEffect(() => {

    const fetchCustomAppointmentByFilterInit = async () => {
      var filter = selectedFilterQueryType;
      //var query = $("#filterQueryInput").val();
      var query = selectedDate;//$("#filterQueryInput").val();
      const formattedDate = query.format('DDMMYYYY');
      var apiUrl = process.env.React_App_AWS_API + `api/appointments?${filter}=${query}`;



      services.getAppointments(query).then((response) => {

        if (response) {
          console.log(JSON.stringify(response));
          const dataObject = response.data;
          const convertedData = convertObjectToFormat(dataObject);
          console.log("convertedData");
          console.log(JSON.stringify(convertedData));
          setCustomAppointments(convertedData);
        }

      });

    };
    handleFilterCustomAppointment(selectedDate, selectedDate.format('DDMMYYYY'));
    setTimeout(function () {
      //fetchCustomAppointmentByFilterInit();
    }, 3000);


  }, []);


  const actions = (patient: ICustomAppointment) => (
    <div className='buttons-list nowrap'>

      <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      {/* <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={handleShowInfo.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>

      <Button onClick={handleShowAddAppointment.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-stethoscope-alt' />
      </Button> */}

    </div>
  );
  const columns: ColumnProps<ICustomAppointment>[] = [
    {
      key: 'patientName',
      dataIndex: 'patientName',
      title: 'patientName',
      sorter: (a, b) => (a.patientName > b.patientName ? 1 : -1),
      render: (patientName) => <strong>{patientName}</strong>
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Phone',
      sorter: (a, b) => (a.phone > b.phone ? 1 : -1),
      render: (phone) => <strong>{phone}</strong>
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      sorter: (a, b) => (a.status > b.status ? 1 : -1),
      render: (status) => <span style={{ minWidth: 200, display: 'block' }}>{status}</span>
    },
    {
      key: 'appointmentDate',
      dataIndex: 'appointmentDate',
      title: 'Appointment Date',
      render: (appointmentDate) => <span style={{ minWidth: 200, display: 'block' }}>{appointmentDate}</span>
    },
    {
      key: 'remarks',
      dataIndex: 'remarks',
      title: 'Remarks',
      render: (remarks) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {remarks}
        </span>
      )
    },
    // {
    //   key: 'visit',
    //   dataIndex: 'lastVisit',
    //   title: 'Last visit',
    //   render: (visit) => (
    //     <span className='nowrap' style={{ color: '#a5a5a5' }}>
    //       {visit.split('T')[0]}
    //     </span>
    //   )
    // },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }


  ];

  const pagination = customAppointments.length <= 10 ? false : {};
  const { Option } = Select;


  ///////End

  return (
    <>


      <div className='row'>
        <div className='col-md-12 col-sm-12'>
          <Card title='' className='mb-0'>
            <Form>
              <div className='row'>
                {/* <div className='col-md-3 col-sm-12'>
                  <Select
                    id="filterQueryBy"                   
                    placeholder='Filter By'
                    defaultValue="date"
                    className='mb-2 mb-md-0'
                    onChange={handleChangefilterQueryBy}
                  >
                    <Option value=''>Filter By</Option>
                    <Option value='date'>Date</Option>
                    <Option value='id'>Appointment ID</Option>
                  </Select>

                </div> */}
                <div className='col-md-3 col-sm-12'>

                  <DatePicker
                    id="filterQueryInput"
                    placeholder='Select Date'
                    className='mb-md-0 mb-4'
                    //format="DDMMYYYY"
                    format="DD MMM YYYY"
                    defaultValue={dayjs()}
                    onChange={handleFilterCustomAppointment}
                  />

                </div>

                {/* <div className='col-md-3 col-sm-12'>
                  <Button block type='primary' onClick={handleFilterCustomAppointment} htmlType='submit'>
                    Filter
                  </Button>
                </div> */}
              </div>
            </Form>
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-sm-12 custom-mb-10'>
        </div>
      </div>


      <Table
        pagination={pagination}
        className='accent-header'
        rowKey={(record) => record.PatientPrimaryKey}
        dataSource={customAppointments}
        columns={columns}
      />


    </>
  );
};

export default CustomAppointmentsPage;
