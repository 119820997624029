import http from "../http-common"
import pubhttp from "../http-public"

class Service {
  getAppointments = async (date) => {
    //alert("test");
    try {
      const response = await http.get(process.env.React_App_AWS_API + `api/appointments?date=` + date);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  getWorkFlow = async (statusCode) => {
    try {
      //alert(statusCode);
      const response = await http.get(process.env.React_App_AWS_API + `api/appointments/` + statusCode + `/workflow`);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

}

export default new Service();