//import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { Alert, Card } from 'antd';
import { Button, Select, Input, DatePicker, message, Space } from 'antd';
import moment from 'moment';


import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'universal-cookie'; 
import ImageLoader from './ImageLoader';
import { hasErrorFactory } from '../../../utils/hasError';

import { IPatient } from '../../../interfaces/patient';
const { Option } = Select;
const { TextArea } = Input;
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'Add patient';
const emptyPatient = {
  phone: null,
  dob: null,
  email: null,
  name: null,
  referrer: null,
  sex: null,
  whatsapp: null,
  registration_id: null,
  language: null,
  familyMember: null,
  parentId: null,
  relationship: null,
  gender:null,
  PatientPrimaryKey:null
};

const patientScheme = Yup.object({
  fname: Yup.string().required(),
  lname: Yup.string().required(),
  address: Yup.string().required(),
  status: Yup.string().required(),
  age: Yup.string().required(),
  number: Yup.string().required(),
  gender: Yup.string().required(),
  img: Yup.string().required()
});

const PatientForm = ({



  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    //handleChange,
    handleSubmit,
    // setValues,
    handleBlur,
    resetForm,
    touched,
    // values,
    //errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      console.log("555555555555")
      onSubmit(values);

      onCancel();
    }
  });

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    whatsapp: '',
    language: '',
    relationship: '',
    dob: ''
  };
  //const [values, setValues] = useState(initialValues);
  const [values, setValues] = useState<any>(initialValues);
  //const [errors, setErrors] = useState({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const handleGenderSelect = (value) => setFieldValue('gender', value);
  const handleStatusSelect = (value) => setFieldValue('status', value);

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };


  const successAlert = (message) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Return true if the email matches the pattern, otherwise return false
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    // Add your phone validation logic here
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleChangeSelect = (fieldName, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateForm = () => {
    console.log(JSON.stringify(values));
    var errors: any = {};

    if (values.name == "") {
      errors.name = 'Name is required';
    }

    if (!values.email && values.email == "") {
      errors.email = 'Email is required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (!isValidPhone(values.phone)) {
      errors.phone = 'Invalid phone number';
    }

    // Add validations for other fields

    return errors;
  };
  const handlePatientAddBtn = (e) => {
   
    setLoading(true);
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      // Form is valid, proceed with submitting
      //
      const patientData = {
        "staffId": "staffId", 
        "data": {
          dob: values.dob || '',
          email: values.email || '',
          name: values.name || '',
          phone: values.phone || '',
          referrer: values.referrer || '',
          gender: values.gender || '',
          reference: values.reference || '',
          land_phone: values.land_phone || '',
          discount_type: values.discount_type || '',
          discount_note: values.discount_note || '',
          whatsapp: values.whatsapp || '',
          registration_id: values.registration_id || '',
          language: values.language || '',
          familyMember: values.familyMember || null,
          parentId: values.parentId || '',
          relationship: values.relationship || '',
          // Include other properties from the form values
        }
       
      };
      const cookies = new Cookies();
      const jwtToken = cookies.get('jwtToken');
      fetch(process.env.React_App_AWS_API+'api/patients', {
        method: 'PUT',
        headers: {
          //'Authorization': "jwtToken=" + jwtToken,
          'Authorization': jwtToken,
          'Content-Type': 'application/json',
          'authority': 'clinic.drmanoj.com',
          'accept': 'application/json, text/plain, */*',
          'accept-language': 'en-US,en;q=0.9',
          'cookie': '_ga=GA1.2.1772364038.1671790294; _gcl_au=1.1.712055127.1680073469',
          'origin': 'https://clinic.drmanoj.com',
          'referer': 'https://clinic.drmanoj.com/users/create?name=Thimmiahgari%20Sanjeevarayappa%20&email=Tsrayappa37%40gmailcom&phone=9247295151&address=',
          'sec-ch-ua': '"Google Chrome";v="113", "Chromium";v="113", "Not-A.Brand";v="24"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"Windows"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-origin',
          'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBvaW50bWVudCI6NywiYXR0YWNobWVudCI6NywiYmxvZyI6NywiY29uc2lnbm1lbnQiOjcsImVucXVpcnkiOjcsImlkIjoiYjdiNDFmZjktMTliNy00OTRiLWE2NWItMWJlOWIzZGQ2MmIwIiwibWVkaWEiOjcsIm1lc3NhZ2UiOjcsIm5vdGlmaWNhdGlvbnMiOjcsInBheW1lbnQiOjcsInByZXNjcmlwdGlvbiI6NywicHJvZHVjdCI6NywicmVjb3JkIjo3LCJyZXBvcnRzIjo3LCJyb2xlIjoiYWRtaW4iLCJzZWFyY2giOjMsInVzZXIiOjd9.NCxz9ZVbPZekoH-x1ZJykEkocZqOdeoLTKDFRD9Zdjg',
          'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36'
        },
        body: JSON.stringify(patientData),
      })
        .then((response) => {
          if (response.status) {
            console.log('Patient added successfully');
            $("#AddPatientFormSuccess").show();
            setLoading(false); 

            successAlert("pt added successfully");
            //$("#appointMentCreateSuccess").show();
           

            const timerId = setTimeout(() => {
              //$("#AddPatientFormSuccess").hide();
              handleCancel();
             
            }, 3000);
            /*
            const timerId = setTimeout(() => {
              $("#AddPatientFormSuccess").hide();
              handleCancel();
             
            }, 5000);
            */


            // Handle success response here
          } else {
            throw new Error('Failed to add patient');
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message;
          // Display the error message in the UI using the notification library or any other method you prefer
          messageApi.open({
            type: 'error',
            content: errorMessage,
          });
        } else {
          // If the response data is not available, show a generic error message
          messageApi.open({
            type: 'error',
            content: 'An error occurred while processing your request.',
          });
        }
          // Handle error here
        });
      //
    }
    setLoading(false); 


  };

  /*
  const handleImageLoad = (img) => {
    setValues({ ...values, img });
  };
  */


  useEffect(() => {
    // Code to run when the component mounts
    $("#AddPatientFormSuccess").hide();
    // Return a cleanup function if needed
    return () => {
      // Code to run before the component unmounts or before the effect is re-run
    };
  }, []); // Empty dependency array means the effect will only run once

  return (
    <>
       {contextHolder}
      <form >
        {/* <div className='form-group'>
          <ImageLoader onLoad={handleImageLoad} src={values.img as string} />
        </div> */}

        <div className='form-group'>
          <Input
            placeholder='Name'
            name='name'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.name}
            className={hasError('name')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.name}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='Email'
            name='email'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.email}
            className={hasError('email')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.email}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='Phone'
            name='phone'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.phone}
            className={hasError('phone')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.phone}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='LandPhone'
            name='land_phone'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.land_phone}
            className={hasError('land_phone')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.land_phone}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='Whatsapp'
            name='whatsapp'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.whatsapp}
            className={hasError('whatsapp')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.whatsapp}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='Language'
            name='language'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.language}
            className={hasError('language')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.language}</div>}
        </div>

        {/* <div className='form-group'>
          <Input
            placeholder='DOB'
            name='dob'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.dob}
            className={hasError('dob')}
          />
          {errors.name && <div className='error-message custom-form-item-explain-error'>{errors.dob}</div>}
        </div> */}


        <div className='form-group'>
          <DatePicker
            placeholder='Select date of birth'
            name='dob' // Use 'dob' as the name attribute for form data
            onBlur={handleBlur}
            onChange={(date, dateString) => handleChangeSelect('dob', dateString)} // Update the handleChange function accordingly
            className={errors.dob ? 'has-error' : ''}
          />
          {errors.dob && <div className='error-message custom-form-item-explain-error'>{errors.dob}</div>}
        </div>

        <div className='form-group'>
          <Input
            placeholder='Reference'
            name='reference'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.reference}
            className={hasError('reference')}
          />
        </div>

        <div className='form-group'>
          <div onBlur={handleBlur} className={errors.gender ? 'has-error' : ''}>
            <Select
              placeholder='Select gender'
              onChange={(value) => handleChangeSelect('gender', value)}
              defaultValue={values.gender}
            >
              <Option value='male'>Male</Option>
              <Option value='female'>Female</Option>
              <Option value='other'>Other</Option>
            </Select>
          </div>
          {errors.gender && <div className='error-message custom-form-item-explain-error'>{errors.gender}</div>}
        </div>

        <div className='form-group'>
          <div onBlur={handleBlur} className={errors.gender ? 'has-error' : ''}>
            <Select
              placeholder='Discount Type'             
              onChange={(value) => handleChangeSelect('discount_type', value)}
              defaultValue={values.discount_type}
            >
              <Option value='No Discount'>No Discount</Option>
              <Option value='Partial Discount'>Partial Discount</Option>
              <Option value='Full Discount'>Full Discount</Option>
            </Select>
          </div>
          {errors.discount_type && <div className='error-message custom-form-item-explain-error'>{errors.discount_type}</div>}
        </div>

        
        <div className='form-group'>
          <Input
            placeholder='Discount Note'
            name='discount_note'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.discount_note}
            className={hasError('discount_note')}
          />
        </div>


        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          {/* <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button> */}

          <Button loading={loading} type='primary' onClick={handlePatientAddBtn}>
            {submitText}
          </Button>
          <div id='AddPatientFormSuccess' style={{display:"none"}}>
            <Alert  type='success' message='Patient added successfully' />
          </div>

        </div>
      </form>
    </>
  );
};

export default PatientForm;
